import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeArticleMapper } from '../mappers/node-article.mapper';

export const provideNodeBigArticle = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeArticleMapper.type],
            useClass: NodeArticleMapper,
        },
    ]);
