import { Injectable, inject } from '@angular/core';
import {
    JsonApiResourceMapper,
    ResourceIdentifierMapperService,
    ResourceMapper,
    ResourceTypes,
    type ResourceCollection,
} from '@big-direkt/json-api-client';
import { NodeTypes, type NodeModel } from '@big-direkt/utils/shared';
import { NodeDPPPrivateAreaResource } from '../resources/node-dpp-private-area.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeDPPPrivateAreaMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeDPPPrivateArea;
    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: NodeDPPPrivateAreaResource, collection: ResourceCollection): NodeModel {
        return {
            type: NodeTypes.DPPPrivateArea,
            nodeId: resource.attributes.node_id ?? 0,
            breadcrumbs: resource.attributes.breadcrumbs ?? [],
            heading: resource.attributes.headline_h1 ?? resource.attributes.title,
            introduction: resource.attributes.plain_text_introduction,
            metadata: resource.attributes.metatag ?? [],
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
        };
    }
}
