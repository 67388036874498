import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigDashboardMapper } from '../mappers/node-big-dashboard.mapper';

export const provideNodeBigDashboard = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigDashboardMapper.type],
            useClass: NodeBigDashboardMapper,
        },
    ]);
