import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { UserDetailsItem } from './user-details-item.model';
import { UserDetailsItemResource } from './user-details-item.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class UserDetailsItemMapper extends ResourceMapper<UserDetailsItem> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigUserDetailsItem;

    public map(resource: UserDetailsItemResource): UserDetailsItem {
        return {
            type: ParagraphTypes.UserDetailsItem,
            title: resource.attributes.title,
            textWithPropertyTokens: resource.attributes.user_profile_path ?? '',
            ...(!!resource.attributes.link && {
                link: {
                    title: resource.attributes.link.title || undefined,
                    href: resource.attributes.link.url,
                },
            }),
        };
    }
}
