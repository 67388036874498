import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type VaccinationTravelResource } from '../models/vaccination-travel.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class VaccinationTravelYellowFeverCountriesMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.VaccinationTravelYellowFeverCountries;

    public map(resource: VaccinationTravelResource): string[] {
        return resource.attributes.map(item => item.name);
    }
}
