import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigFormMapper } from '../mappers/node-big-form.mapper';

export const provideNodeBigForm = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigFormMapper.type],
            useClass: NodeBigFormMapper,
        },
    ]);
