import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { MybigGeneralItemMapper } from './mybig-general-item.mapper';
import { MybigGeneralMapper } from './mybig-general.mapper';

export const provideMyBigGeneralParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[MybigGeneralMapper.type], useClass: MybigGeneralMapper },
        { provide: MapperInjectorService.tokens[MybigGeneralItemMapper.type], useClass: MybigGeneralItemMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./mybig-general.component'),
                type: ParagraphTypes.MyBigGeneral,
            },
            multi: true,
        },
    ]);
