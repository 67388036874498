import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ImageMapper } from './image.mapper';
import { ParagraphTypes } from '@big-direkt/utils/shared';

export const provideImageParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[ImageMapper.type], useClass: ImageMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./image.component'),
                type: ParagraphTypes.Image,
            },
            multi: true,
        },
    ]);
