import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, type UrlSerializer, type UrlTree } from '@angular/router';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type IframeModel } from './iframe.model';
import { type IframeResource } from './iframe.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class IframeMapper extends ResourceMapper<IframeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigIframe;

    public map(resource: IframeResource): IframeModel {
        return {
            type: ParagraphTypes.Iframe,
            height: resource.attributes.height ?? undefined,
            url: this.bypassServiceWorker(resource.attributes.link.uri),
            forwardSession: resource.attributes.forward_session,
        };
    }

    private bypassServiceWorker(url: string): string {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            // for absolute urls
            const urlParts: URL = new URL(url);
            urlParts.searchParams.append('ngsw-bypass', 'true');

            return urlParts.toString();
        }

        // for relative paths
        const urlSerializer: UrlSerializer = new DefaultUrlSerializer();
        const urlTree: UrlTree = urlSerializer.parse(url);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        urlTree.queryParams = { ...urlTree.queryParams, 'ngsw-bypass': true };

        return urlSerializer.serialize(urlTree);
    }
}
