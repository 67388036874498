import { SERVICE_TOOLS_MAP, type ServiceToolsMapItem } from '@big-direkt/service-tools/base';
import { type HomeopathyDoctorSearchComponent } from './../components/homeopathy-doctor-search.component';
import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

export const provideHomeopathyDoctorSearchServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/homeopathy-doctor-search.component'),
                id: 'homeopathy-doctor-search',
            } as ServiceToolsMapItem<HomeopathyDoctorSearchComponent>,
            multi: true,
        },
    ]);
