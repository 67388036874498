import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes, type Meta } from '@big-direkt/json-api-client';
import { type QuickLinkListItemModel } from '@big-direkt/ui/quick-link-list';
import { type QuickLinkFileResource } from './quick-link-file.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class QuickLinkFileMapper extends ResourceMapper<QuickLinkListItemModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphQuickLinkFile;

    public map(resource: QuickLinkFileResource): QuickLinkListItemModel {
        const fileInfo: Meta = resource.relationships.file.data.meta;

        return {
            icon: resource.relationships.icon.data?.meta?.uri ?? '',
            title: fileInfo.title,
            url: fileInfo.uri,
            file: {
                size: fileInfo.size,
                type: fileInfo.type,
            },
        };
    }
}
