import { Injectable } from '@angular/core';
import { type BreadcrumbItemModel, type NodeModel, NodeTypes } from '@big-direkt/utils/shared';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeBigFormOverviewResource } from '../resources/node-big-form-overview.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigFormOverviewMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeBigFormOverview;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeBigFormOverviewResource, collection: ResourceCollection): NodeModel {
        const breadcrumbs: BreadcrumbItemModel[] = resource.attributes.breadcrumbs ?? [];

        return {
            type: NodeTypes.FormOverview,
            nodeId: resource.attributes.node_id ?? 0,
            heading: resource.attributes.headline_h1 ?? resource.attributes.title,
            pageTitle: {},
            introduction: resource.attributes.plain_text_introduction,
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
            breadcrumbs,
            metadata: resource.attributes.metatag ?? [],
            created: resource.attributes.created,
            changed: resource.attributes.changed,
        };
    }
}
