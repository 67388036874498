import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { MapperInjectorService as RestApiMapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { InsuranceServicesMapper } from '../mapper/insurance-services.mapper';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { InsuranceServicesNodesMapper } from '../mapper/insurance-services-nodes.mapper';

export const provideInsuranceServicesParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.InsuranceServicesNodes]: '/api/insurance-services-nodes' }, multi: true },
        { provide: RestApiMapperInjectorService.tokens[InsuranceServicesNodesMapper.type], useClass: InsuranceServicesNodesMapper },
        { provide: MapperInjectorService.tokens[InsuranceServicesMapper.type], useClass: InsuranceServicesMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('../components/insurance-services.component'),
                type: ParagraphTypes.InsuranceServices,
            },
            multi: true,
        },
    ]);
