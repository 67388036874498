import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeQuickLinkMapper } from '../mappers/node-quick-link.mapper';

export const provideNodeQuickLink = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeQuickLinkMapper.type],
            useClass: NodeQuickLinkMapper,
        },
    ]);
