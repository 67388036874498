import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { ListItemMapper } from './list-item.mapper';
import { ListMapper } from './list.mapper';

export const provideListParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[ListMapper.type], useClass: ListMapper },
        { provide: MapperInjectorService.tokens[ListItemMapper.type], useClass: ListItemMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./list.component'),
                type: ParagraphTypes.List,
            },
            multi: true,
        },
    ]);
