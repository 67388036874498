import { Injectable } from '@angular/core';
import { type ParagraphAccordionModel, ParagraphTypes } from '@big-direkt/utils/shared';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeBigAccordionResource } from '../resources/node-big-accordion.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigAccordionMapper extends ResourceMapper<ParagraphAccordionModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeAccordion;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeBigAccordionResource, collection: ResourceCollection): ParagraphAccordionModel {
        return {
            type: ParagraphTypes.Accordion,
            nodeId: resource.attributes.node_id ?? 0,
            title: resource.attributes.title,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            items: this.resourceIdentifierMapper.mapMultiple(resource.relationships.big_accordion_items.data, collection)!,
        };
    }
}
