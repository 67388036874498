import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { TextButtonModel } from './text-button.model';
import { TextButtonResource } from './text-button.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class TextButtonMapper extends ResourceMapper<TextButtonModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphTextButton;

    public constructor() {
        super();
    }

    public map(resource: TextButtonResource): TextButtonModel {
        return {
            type: ParagraphTypes.TextButton,
            heading: resource.attributes.heading,
            content: resource.attributes.text_long_unformatted ?? undefined,
            link: {
                href: resource.attributes.link?.uri ?? '',
                title: resource.attributes.link?.title,
            },
            svgIcon: resource.relationships.icon.data?.meta?.uri ?? undefined,
        };
    }
}
