import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type LocationsModel } from '../model/locations.model';
import { type LocationsResource } from '../resources/locations.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class LocationsMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.AppliancesSearchLocation;

    public map(resource: LocationsResource): LocationsModel {
        return {
            items: resource.attributes,
        };
    }
}
