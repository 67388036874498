import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type AliveLinkModel } from './alive-link.model';
import { type AliveLinkResource } from './alive-link.resource';

@Injectable({ providedIn: 'any' })
@RestApiResourceMapper
export class AliveLinkMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.AliveLink;

    public map(resource: AliveLinkResource): AliveLinkModel {
        return {
            link: resource.attributes.data.url,
        };
    }
}
