import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type DashboardBigtionaerModel } from './dashboard-bigtionaer.model';
import { type DashboardBigtionaerResource } from './dashboard-bigtionaer.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class DashboardBigtionaerMapper extends ResourceMapper<DashboardBigtionaerModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigDashboardBigtionaer;

    public map(resource: DashboardBigtionaerResource): DashboardBigtionaerModel {
        const card: DashboardBigtionaerModel = {
            type: ParagraphTypes.DashboardBigtionaer,
            icon: resource.relationships.icon.data?.meta?.uri,
            text: resource.attributes.text ?? undefined,
        };

        // eslint-disable-next-line no-null/no-null
        if (resource.attributes.link !== null) {
            card.link = {
                title: resource.attributes.link.title ?? '',
                href: resource.attributes.link.uri ?? '',
            };
        }

        return card;
    }
}
