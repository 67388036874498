import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type C2aBannerModel } from './c2a-banner.model';
import { type C2aBannerResource } from './c2a-banner.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class C2aBannerMapper extends ResourceMapper<C2aBannerModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigC2aBanner;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: C2aBannerResource, collection: ResourceCollection): C2aBannerModel {
        const node: C2aBannerModel = {
            type: ParagraphTypes.C2aBanner,
            title: resource.attributes.title,
            text: resource.attributes.description ?? undefined,
            list: resource.attributes.list ?? undefined,
            link: {
                title: resource.attributes.link.title,
                href: resource.attributes.link.uri,
            },
        };

        // eslint-disable-next-line no-null/no-null
        if (resource.relationships.image.data !== null) {
            node.image = this.resourceIdentifierMapper.map(resource.relationships.image.data, collection);
        }

        return node;
    }
}
