import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { CookiebotMapper } from './cookiebot.mapper';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';

export const provideCookiebotParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[CookiebotMapper.type], useClass: CookiebotMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./cookiebot.component'),
                type: ParagraphTypes.Cookiebot,
            },
            multi: true,
        },
    ]);
