import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { type ActivatedRouteSnapshot, type CanActivate } from '@angular/router';
import { PlatformService } from '@big-direkt/utils/shared';

@Injectable({ providedIn: 'any' })
export class CanActivatePhoneNumbersGuard implements CanActivate {
    public constructor(
        private readonly platformService: PlatformService,
        @Inject(DOCUMENT) private readonly document: Document,
    ) {}

    public canActivate(_route: ActivatedRouteSnapshot): boolean {
        if (!_route.url.length) {
            return true;
        }

        const path: string = _route.url[0].path;
        if (/(tel|fax):\d+/.test(path)) {
            if (this.platformService.isPlatformServer()) {
                return false;
            }

            this.document.location.assign(path);

            return false;
        }

        return true;
    }
}
