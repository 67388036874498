import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigNewsMapper } from '../mappers/node-big-news.mapper';

export const provideNodeBigNews = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigNewsMapper.type],
            useClass: NodeBigNewsMapper,
        },
    ]);
