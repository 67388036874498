import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import {
    ParagraphTypes,
    TableRenderingEnum,
    type ParagraphTableModel,
    type TableCompositeCellType,
    type TableHeaderCellModel,
    type TableRowModel,
} from '@big-direkt/utils/shared';
import { type TableValueObject } from './table-value-object';
import { type TableResource } from './table.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class TableMapper extends ResourceMapper<ParagraphTableModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphTable;

    public map(resource: TableResource): ParagraphTableModel {
        const rendering: TableRenderingEnum = resource.attributes.table_rendering;
        let headers: TableHeaderCellModel[] | undefined;

        const formattedValues = Object.values(resource.attributes.table_content.value)
            // eslint-disable-next-line sonarjs/function-return-type
            .map((value: string[] | TableValueObject | string): string[] | string => {
                if (Array.isArray(value)) {
                    return value;
                }

                if (typeof value === 'object') {
                    const res: string[] = [];

                    for (const key of Object.keys(value).filter(k => k !== 'weight')) {
                        res.push(value[key as unknown as number] as string);
                    }

                    return res;
                }

                return value;
            });

        const sanatizedValues: string[][] = formattedValues.filter(
            (value: string[] | string): value is string[] => Array.isArray(value), // JSON API delivers caption in this value array;
        );

        if (rendering === TableRenderingEnum.Top) {
            headers = sanatizedValues.shift()?.map((value: string) => ({ value }));
        }

        const rows: TableRowModel = sanatizedValues.map((row: string[]) =>
            row.map<TableCompositeCellType>((cellValue: string) => ({ type: 'html', value: cellValue })),
        );

        return {
            type: ParagraphTypes.Table,
            rendering,
            caption: resource.attributes.table_content.caption,
            headers,
            rows,
        };
    }
}
