import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { SelectOption } from '@big-direkt/ui/select';
import { FamilyHealthRetreatSearchResult } from '../models/family-health-retreat-search-result.model';
import { FamilyHealthRetreatSearchResource } from '../models/family-health-retreat-search.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class FamilyHealthRetreatSearchMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.FamilyHealthRetreatSearchData;

    public map(resource: FamilyHealthRetreatSearchResource): FamilyHealthRetreatSearchResult {
        return {
            facilities: resource.attributes,
            specialities: this.mapSpecialities(resource.attributes),
        };
    }

    private mapSpecialities(facilities: FamilyHealthRetreatSearchResource['attributes']): SelectOption<string>[] {
        const specialities: string[] = [];

        facilities.forEach(facility => {
            facility.speciality.forEach(speciality => {
                if (specialities.includes(speciality)) {
                    return;
                }

                specialities.push(speciality)
            })
        })

        // eslint-disable-next-line sonarjs/no-misleading-array-reverse,sonarjs/no-alphabetical-sort
        return specialities.sort().map(speciality => ({
            key: speciality,
            value: speciality,
        }));
    }
}
