@if (items) {
    <ng-container>
        @for (item of items; track item.id; let i = $index) {
            <div class="bg-primary">
                <div
                    class="flex container gap-2 text-center text-white tracking-[0.5px] overflow-y-hidden content-start justify-center"
                    [@leaveAnimation]>
                    <div
                        class="p-2 break-words font-ci [&>*:last-child]:mb-0"
                        [innerHTML]="item.html"></div>
                    <button
                        [id]="item.id"
                        (click)="closeTopBar(i)"
                        class="leading-[0] btn btn-collapsed h-fit">
                        <span class="visually-hidden">{{ 'topBar.closeMessage' | transloco }}</span>
                        <big-icon
                            [icon]="iconClose"
                            class="[&>svg]:!w-[20px] [&>svg]:!h-[20px] py-2.5 fill-white" />
                    </button>
                </div>
            </div>
        }
    </ng-container>
}
