import { Injectable } from '@angular/core';
import { type NodeModel, NodeTypes } from '@big-direkt/utils/shared';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeBigDashboardResource } from '../resources/node-big-dashboard.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigDashboardMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeDashboard;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeBigDashboardResource, collection: ResourceCollection): NodeModel {
        return {
            type: NodeTypes.Dashboard,
            nodeId: resource.attributes.node_id ?? 0,
            heading: resource.attributes.headline_h1 ?? resource.attributes.title,
            pageTitle: {},
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
            metadata: resource.attributes.metatag ?? [],
            breadcrumbs: resource.attributes.breadcrumbs ?? [],
        };
    }
}
