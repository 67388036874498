import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ResourceTypes, RESOURCE_PATH_TOKEN } from '@big-direkt/rest-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { MybigMedicalAppointmentMapper } from './mybig-medical-appointment.mapper';

export const provideMyBigMedicalAppointmentParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.MedicalAppointment]: '/users/insurant/appointments' }, multi: true },
        { provide: MapperInjectorService.tokens[MybigMedicalAppointmentMapper.type], useClass: MybigMedicalAppointmentMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./mybig-medical-appointment.component'),
                type: ParagraphTypes.MyBigMedicalAppointment,
            },
            multi: true,
        },
    ]);
