import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { NewsTeaserModel } from './news-teaser.model';
import { NewsTeaserResource } from './news-teaser.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NewsTeaserMapper extends ResourceMapper<NewsTeaserModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigNewsTeaser;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NewsTeaserResource): NewsTeaserModel {
        return {
            type: ParagraphTypes.NewsTeaser,
            newsArticleCount: resource.attributes.news_article_count,
            title: resource.attributes.title ?? undefined,
        };
    }
}
