import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigMyBigMapper } from '../mappers/node-big-mybig.mapper';

export const provideNodeBigMyBig = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigMyBigMapper.type],
            useClass: NodeBigMyBigMapper,
        },
    ]);
