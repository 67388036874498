import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type TableCompositeCellType, type TableRowModel } from '@big-direkt/utils/shared';
import { type ImageCreditsResultsRowResource } from './image-credits-results-row.resource';
import { type ImageCreditsResultsModel } from './image-credits-results.model';
import { type ImageCreditsResultsResource } from './image-credits-results.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class ImageCreditsResultsMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.ImageCreditsResults;

    public map(resource: ImageCreditsResultsResource): ImageCreditsResultsModel {
        const rows: TableRowModel = resource.attributes.rows.map((row: ImageCreditsResultsRowResource): TableCompositeCellType[] => [
            {
                type: 'image',
                cssClass: 'md:w-[220px]',
                value: {
                    defaultSourceUrl: row.image,
                    altText: `Urheber: ${row.copyright} / Lizenzgeber: ${row.source}`,
                },
            },
            {
                type: 'string',
                value: row.copyright,
            },
            {
                type: 'string',
                value: row.source,
            },
        ]);

        return {
            pager: {
                totalPages: resource.attributes.pager.total_pages,
                totalItems: parseInt(resource.attributes.pager.total_items, 10),
                itemsPerPage: resource.attributes.pager.items_per_page,
                currentPage: resource.attributes.pager.current_page,
            },
            table: {
                headers: [
                    { value: 'Bild' },
                    { value: 'Urheber' },
                    { value: 'Lizenzgeber' },
                ],
                rows,
            },
        };
    }
}
