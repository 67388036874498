import { inject, Injectable } from '@angular/core';
import {
    JsonApiResourceMapper,
    type ResourceCollection,
    ResourceIdentifierMapperService,
    ResourceMapper,
    ResourceTypes,
} from '@big-direkt/json-api-client';
import { type VideoModel } from './video.model';
import { type VideoResource } from './video.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class VideoMapper extends ResourceMapper<VideoModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphVideo;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: VideoResource, collection: ResourceCollection): VideoModel {
        return {
            title: resource.attributes.title,
            text: resource.attributes.text,
            video: {
                id: resource.attributes.video_id,
                source: resource.attributes.video_source,
            },
            image: this.resourceIdentifierMapper.map(resource.relationships.image.data, collection),
        };
    }
}
