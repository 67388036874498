import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes, type TextModel } from '@big-direkt/utils/shared';
import { type TextResource } from './text.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class TextMapper extends ResourceMapper<TextModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphText;

    public map(resource: TextResource): TextModel {
        return {
            type: ParagraphTypes.Text,
            content: resource.attributes.text_long_formatted.processed,
        };
    }
}
