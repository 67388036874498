import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type FormOverview } from './form-overview.model';
import { type FormOverviewResource } from './form-overview.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class FormOverviewMapper extends ResourceMapper<FormOverview> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphFormOverview;

    public map(resource: FormOverviewResource): FormOverview {
        return {
            type: ParagraphTypes.FormOverview,
            parentServiceCategoryId: resource.relationships.service_category.data.meta.drupal_internal__target_id.toString(),
            hideSearch: resource.attributes?.hide_search ?? false,
        };
    }
}
