import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigTemplateMapper } from '../mappers/node-big-template.mapper';

export const provideNodeBigTemplate = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigTemplateMapper.type],
            useClass: NodeBigTemplateMapper,
        },
    ]);
