import { type MetadataModel } from '@big-direkt/utils/shared';

export const metaTags: MetadataModel[] = [
    {
        tag: 'meta',
        attributes: {
            name: 'title',
            content: 'Die Krankenkasse BIG direkt gesund - top Service, top Leistungen',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'language',
            content: 'de',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'format-detection',
            content: 'telephone=no',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'theme-color',
            content: '#1976d2',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'apple-mobile-web-app-capable',
            content: 'yes',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'msapplication-tap-highlight',
            content: 'no',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'language',
            content: 'de',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'format-detection',
            content: 'telephone=no',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'theme-color',
            content: '#1976d2',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'apple-mobile-web-app-capable',
            content: 'yes',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'msapplication-tap-highlight',
            content: 'no',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'google-site-verification',
            content: 'CnUCRJ4frHjTNuqtMrwWD5xYMEatxNwRj8R9p3gdBHc',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'apple-itunes-app',
            content: 'app-id=426961160',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'fb:pages',
            content: 'https://www.facebook.com/BIG',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'og:site_name',
            content: 'BIG direkt gesund',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'og:street_address',
            content: 'Rheinische Straße 1',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'og:locality',
            content: 'Dortmund',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'og:region',
            content: 'NRW',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'og:postal_code',
            content: '44137',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'og:country_name',
            content: 'Deutschland',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'og:email',
            content: 'info@big-direkt.de',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'og:phone_number',
            content: '+4980054565456',
        },
    },
    {
        tag: 'meta',
        attributes: {
            property: 'og:locale',
            content: 'de_DE',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'twitter:card',
            content: 'summary',
        },
    },
    {
        tag: 'meta',
        attributes: {
            name: 'twitter:site',
            content: '@BIG',
        },
    },
];
