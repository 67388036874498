import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService, ResourceTypes } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { VideoGroupMapper } from './video-group.mapper';
import { VideoMapper } from './video.mapper';

export const provideVideoGroupParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[ResourceTypes.ParagraphVideoGroup],
            useClass: VideoGroupMapper,
        },
        {
            provide: MapperInjectorService.tokens[ResourceTypes.ParagraphVideo],
            useClass: VideoMapper,
        },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./video-group.component'),
                type: ParagraphTypes.VideoGroup,
            },
            multi: true,
        },
    ]);
