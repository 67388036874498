import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type BgmButtonModel } from './bgm-button.model';
import { type BgmButtonResource } from './bgm-button.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class BgmButtonMapper extends ResourceMapper<BgmButtonModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigBgmButton;

    public map(resource: BgmButtonResource): BgmButtonModel {
        return {
            type: ParagraphTypes.BgmButton,
            title: resource.attributes.title,
        };
    }
}
