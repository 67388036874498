import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type ListItem } from './list-item.model';
import { type ListItemResource } from './list-item.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class ListItemMapper extends ResourceMapper<ListItem> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphListItem;

    public map(resource: ListItemResource): ListItem {
        return {
            type: ParagraphTypes.ListItem,
            title: resource.attributes.heading,
            text: resource.attributes.text,
            link: {
                title: resource.attributes.link?.title ?? '',
                href: resource.attributes.link?.uri ?? '',
            },
        };
    }
}
