import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type PersonalDataModel } from './personal-data.model';
import { type PersonalDataResource } from './personal-data.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class PersonalDataMapper extends ResourceMapper<PersonalDataModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigMyBigPersonalData;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: PersonalDataResource): PersonalDataModel {
        return {
            type: ParagraphTypes.PersonalData,
            title: resource.attributes.title,
            icon: resource.relationships.icon.data?.meta?.uri ?? undefined,
            lastname: resource.attributes.lastname.uri,
            photo: resource.attributes.photo.uri,
            healthInsuranceCard: resource.attributes.health_insurance_card.uri,
            pinPuk: resource.attributes.pin_puk?.uri ?? '',
            residence: resource.attributes.residence.uri,
            taxId: resource.attributes.tax_id.uri,
        };
    }
}
