import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type ChatModel } from './chat.model';
import { type ChatResource } from './chat.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class ChatMapper extends ResourceMapper<ChatModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigChat;

    public map(resource: ChatResource): ChatModel {
        return {
            type: ParagraphTypes.Chat,
            title: resource.attributes.title,
            text: resource.attributes.text,
            mode: resource.attributes.chat_type,
        };
    }
}
