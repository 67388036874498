import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type InsuranceServicesModel } from '../models/insurance-services.model';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class InsuranceServicesMapper extends ResourceMapper<InsuranceServicesModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigInsuranceServices;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(): InsuranceServicesModel {
        return {
            type: ParagraphTypes.InsuranceServices,
        };
    }
}
