import { inject, Injectable } from '@angular/core';
import { type ActivatedRouteSnapshot, type CanActivate, type RouterStateSnapshot } from '@angular/router';
import { EnvironmentService, WindowService } from '@big-direkt/utils/environment';
import { PlatformService } from '@big-direkt/utils/shared';

@Injectable({ providedIn: 'any' })
export class CanActivateBlacklistedUrlsGuard implements CanActivate {
    private readonly environmentService = inject(EnvironmentService);
    private readonly platformService = inject(PlatformService);
    private readonly windowService = inject(WindowService);

    public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.environmentService.routerBlacklistedUrls) {
            return true;
        }

        if (!RegExp(this.environmentService.routerBlacklistedUrls, 'i').test(state.url)) {
            return true;
        }

        if (this.platformService.isPlatformServer()) {
            return false;
        }

        this.windowService.document().location.assign(`${this.windowService.document().location.origin}${state.url}`);

        return false;
    }
}
