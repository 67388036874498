import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { AdvantageCalculatorResultModel } from '../models/advantage-calculator-result.model';
import { AdvantageCalculatorResource } from '../models/advantage-calculator.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class AdvantageCalculatorMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.AdvantageCalculatorServiceToolData;

    public map(resource: AdvantageCalculatorResource): AdvantageCalculatorResultModel {
        return {
            individualAdvantages: resource.attributes.individual_advantages,
            bigServices: resource.attributes.big_services,
            digitalServices: resource.attributes.digital_services,
            becomeMemberHref: resource.attributes.become_member_href,
        };
    }
}
