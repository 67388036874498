import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type C2aSearchModel, ParagraphTypes } from '@big-direkt/utils/shared';
import { type SearchBoxResource } from './search-box.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class SearchBoxMapper extends ResourceMapper<C2aSearchModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphC2aSearch;

    public map(resource: SearchBoxResource): C2aSearchModel {
        return {
            type: ParagraphTypes.C2aSearch,
            title: resource.attributes.c2a_search_title,
            placeholder: resource.attributes.c2a_search_placeholder,
        };
    }
}
