import { Injectable, inject } from '@angular/core';
import { JsonApiResourceMapper, type ResourceCollection, ResourceMapper, ResourceTypes, ResourceIdentifierMapperService } from '@big-direkt/json-api-client';
import { type ParagraphAccordionModel, ParagraphTypes } from '@big-direkt/utils/shared';
import { type BigtionaerModel } from './models/bigtionaer.model';
import { type BigtionaerResource } from './bigtionaer.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class BigtionaerMapper extends ResourceMapper<BigtionaerModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigBigtionaer;
    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: BigtionaerResource, collection: ResourceCollection): BigtionaerModel {
        const accordion: ParagraphAccordionModel | undefined = resource.relationships.accordion_node.data
            ? this.resourceIdentifierMapper.map(resource.relationships.accordion_node.data, collection)
            : undefined;

        return {
            type: ParagraphTypes.Bigtionaer,
            accountingYearText: resource.attributes.accounting_year_text ?? undefined,
            previousBookingYear: resource.attributes.previous_booking_year ?? undefined,
            webformMeasureUploadLink: resource.attributes.measure_upload ?? undefined,
            webformPayoutOptionsLink: resource.attributes.payment_options ?? undefined,
            webformPlusBonusLink: resource.attributes.plusbonus ?? undefined,
            accordion,
        };
    }
}
