import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type CardGroupModel } from './card-group.model';
import { type CardGroupResource } from './card-group.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class CardGroupMapper extends ResourceMapper<CardGroupModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigCardGroup;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: CardGroupResource, collection: ResourceCollection): CardGroupModel {
        return {
            type: ParagraphTypes.CardGroup,
            rendering: resource.attributes.card_group_rendering,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            items: this.resourceIdentifierMapper.mapMultiple(resource.relationships.card_group_card.data, collection)!,
            columns: resource.attributes.card_group_columns ?? undefined,
            title: resource.attributes.card_group_title ?? undefined,
        };
    }
}
