import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { AliveButtonMapper } from './alive-button.mapper';
import { AliveLinkMapper } from './alive-link.mapper';
import { MapperInjectorService as JsonApiMapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { MapperInjectorService as RestApiMapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';

export const provideAliveButtonParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: JsonApiMapperInjectorService.tokens[AliveButtonMapper.type], useClass: AliveButtonMapper },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.AliveLink]: '/api/alive/sign' }, multi: true },
        { provide: RestApiMapperInjectorService.tokens[AliveLinkMapper.type], useClass: AliveLinkMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./alive-button.component'),
                type: ParagraphTypes.AliveButton,
            },
            multi: true,
        },
    ]);
