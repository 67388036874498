import { Injectable } from '@angular/core';
import { type VaccinationCheckModel } from '../models/vaccination-check.model';
import { type VaccinationCheckResource } from '../models/vaccination-check.resource';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class VaccinationCheckMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.VaccinationCheckServiceToolData;

    public map(resource: VaccinationCheckResource): VaccinationCheckModel[] {
        return resource.attributes.map(item => ({
            id: item.id,
            monthMin: item.month_min,
            monthMax: item.month_max,
            label: item.label,
            text: item.text.replace(/\\r/g, '').replace(/\\"/g, '"'),
        }));
    }
}
