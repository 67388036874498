import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type ImageCreditsModel, ParagraphTypes } from '@big-direkt/utils/shared';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class ImageCreditsMapper extends ResourceMapper<ImageCreditsModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigImageCredits;

    public map(): ImageCreditsModel {
        return {
            type: ParagraphTypes.ImageCredits,
        };
    }
}
