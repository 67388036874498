import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type VaccinationTravelModel } from '../models/vaccination-travel.model';
import { type VaccinationTravelResource } from '../models/vaccination-travel.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class VaccinationTravelCountriesMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.VaccinationTravelCountries;

    public map(resource: VaccinationTravelResource): VaccinationTravelModel[] {
        return resource.attributes.map(item => ({
            name: item.name,
            id: item.uid,
        }));
    }
}
