import { type ToothReplacementSubsidyResource } from './../models/tooth-replacement-subsidy.resource';
import { Injectable } from '@angular/core';
import { type ToothReplacementSubsidyModel } from '../models/tooth-replacement-subsidy.model';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class ToothReplacementSubsidyMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.ToothReplacementSubsidyServiceToolData;

    public map(resource: ToothReplacementSubsidyResource): ToothReplacementSubsidyModel[] {
        return resource.attributes.map(item => ({
            id: item.id,
            category: item.category,
            position: item.position,
            title: `${item.category}.${item.position} - ${item.title}`,
            subvention: item.subvention,
        }));
    }
}
