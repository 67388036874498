import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type BackLinkModel } from './back-link.model';
import { type BackLinkResource } from './back-link.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class BackLinkMapper extends ResourceMapper<BackLinkModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigBackLink;

    public map(resource: BackLinkResource): BackLinkModel {
        return {
            type: ParagraphTypes.BackLink,
            link: {
                title: resource.attributes.single_link?.title,
                // TODO: fix after consulting
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                href: resource.attributes.single_link?.uri,
                target: undefined,
            },
        };
    }
}
