import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigTaggedArticleMapper } from '../mappers/node-big-tagged-article.mapper';

export const provideNodeBigTaggedArticle = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigTaggedArticleMapper.type],
            useClass: NodeBigTaggedArticleMapper,
        },
    ]);
