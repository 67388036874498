import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { SERVICE_TOOLS_MAP, type ServiceToolsMapItem } from '@big-direkt/service-tools/base';
import { ToothReplacementSubsidyMapper } from '../mappers/tooth-replacement-subsidy.mapper';
import type ToothReplacementSubsidyComponent from '../components/tooth-replacement-subsidy.component';

export const provideToothReplacementSubsidyServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [ResourceTypes.ToothReplacementSubsidyServiceToolData]: '/service-tool-data/tooth-replacement-subsidy.json' },
            multi: true,
        },
        {
            provide: MapperInjectorService.tokens[ResourceTypes.ToothReplacementSubsidyServiceToolData],
            useClass: ToothReplacementSubsidyMapper,
        },
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/tooth-replacement-subsidy.component'),
                id: 'tooth-replacement-subsidy',
            } as ServiceToolsMapItem<ToothReplacementSubsidyComponent>,
            multi: true,
        },
    ]);
