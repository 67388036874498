import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { LinkMapper } from './link.mapper';

export const provideLinkParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[LinkMapper.type], useClass: LinkMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./link.component'),
                type: ParagraphTypes.Link,
            },
            multi: true,
        },
    ]);
