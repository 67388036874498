import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { ButtonMapper } from './button.mapper';

export const provideButtonParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[ButtonMapper.type], useClass: ButtonMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./button.component'),
                type: ParagraphTypes.Button,
            },
            multi: true,
        },
    ]);
