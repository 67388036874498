import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type CoBrowsingButtonModel } from './co-browsing-button.model';
import { type CoBrowsingButtonResource } from './co-browsing-button.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class CoBrowsingButtonMapper extends ResourceMapper<CoBrowsingButtonModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphCoBrowsingButton;

    public map(resource: CoBrowsingButtonResource): CoBrowsingButtonModel {
        return {
            type: ParagraphTypes.CoBrowsingButton,
            approveText: resource.attributes.approve_text ?? 'Jetzt Cobrowsing anmelden',
            unapproveText: resource.attributes.unapprove_text ?? 'Cobrowsing beenden',
        };
    }
}
