import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { NodeTypes, type NodeModel } from '@big-direkt/utils/shared';
import { NodeDPPArticleResource } from '../resources/node-dpp-article.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeDPPArticleMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeDPPArticle;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeDPPArticleResource, collection: ResourceCollection): NodeModel {
        const node: NodeModel =  {
            type: NodeTypes.DPPArticle,
            nodeId: resource.attributes.node_id,
            heading: resource.attributes.headline_h1 ?? resource.attributes.title,
            introduction: resource.attributes.plain_text_introduction,
            metadata: resource.attributes.metatag ?? [],
            breadcrumbs: resource.attributes.breadcrumbs ?? [],
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
            href: resource.attributes.path?.alias,
        };

        if (resource.relationships.image_introduction.data) {
            node.pageTitle = {
                picture: this.resourceIdentifierMapper.map(resource.relationships.image_introduction.data, collection),
            };
        }

        return node;
    }
}
