import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { PublicDeliveryMapper } from './public-delivery.mapper';

export const providePublicDeliveryParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[PublicDeliveryMapper.type], useClass: PublicDeliveryMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./public-delivery.component'),
                type: ParagraphTypes.PublicDelivery,
            },
            multi: true,
        },
    ]);
