import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { type QuickLinkListItemModel } from '@big-direkt/ui/quick-link-list';
import { type Maybe } from '@big-direkt/utils/shared';
import { type QuickLinkNodeResource } from './quick-link-node.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class QuickLinkNodeMapper extends ResourceMapper<QuickLinkListItemModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphQuickLinkNode;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: QuickLinkNodeResource, collection: ResourceCollection): Maybe<QuickLinkListItemModel> {
        return this.resourceIdentifierMapper.map(resource.relationships.node.data, collection);
    }
}
