import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type ParagraphCustomerSegmentLinksModel } from './customer-segment-links.model';
import { type CustomerSegmentLinksResource } from './customer-segment-links.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class CustomerSegmentLinksMapper extends ResourceMapper<ParagraphCustomerSegmentLinksModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigCustomerSegmentLinks;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: CustomerSegmentLinksResource, collection: ResourceCollection): ParagraphCustomerSegmentLinksModel {
        return {
            type: ParagraphTypes.CustomerSegmentLinks,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            customerSegment: this.resourceIdentifierMapper.map(resource.relationships.customer_segment.data, collection),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            items: this.resourceIdentifierMapper.mapMultiple(resource.relationships.links.data, collection)!,
        };
    }
}
