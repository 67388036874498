import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type MyBigSickNotesModel } from './mybig-sick-notes.model';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class MyBigSickNotesMapper extends ResourceMapper<MyBigSickNotesModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigMyBigSickNotes;

    public map(): MyBigSickNotesModel {
        return {
            type: ParagraphTypes.MyBigSickNotes,
        };
    }
}
