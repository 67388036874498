import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigFormOverviewMapper } from '../mappers/node-big-form-overview.mapper';

export const provideNodeBigFormOverview = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigFormOverviewMapper.type],
            useClass: NodeBigFormOverviewMapper,
        },
    ]);
