import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type ContactDataModel } from './contact-data.model';
import { type ContactDataResource } from './contact-data.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class ContactDataMapper extends ResourceMapper<ContactDataModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigMyBigContactData;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: ContactDataResource): ContactDataModel {
        return {
            type: ParagraphTypes.ContactData,
            title: resource.attributes.title,
            icon: resource.relationships.icon.data?.meta?.uri ?? undefined,
            link: resource.attributes.link.uri,
        };
    }
}
