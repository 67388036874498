import { Injectable, inject } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type UiCardModel } from '@big-direkt/ui/card';
import { TranslocoService } from '@jsverse/transloco';
import { type ResultsModel } from '../model/results.model';
import { type ItemResource } from '../resources/item.resource';
import { type ResultsResource } from '../resources/results.resource';

const FACTOR_THOUSAND = 1000;

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class ResultsMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.AppliancesSearchResults;
    private readonly translocoService = inject(TranslocoService);

    public map(resource: ResultsResource): ResultsModel {
        return {
            items: resource.attributes.data.items.map((item: ItemResource): UiCardModel => {
                const mapsQuery = encodeURI(`${item.street}, ${item.zip} ${item.city}`);

                return {
                    title: item.company_name,
                    text: [item.street, `${item.zip} ${item.city}`, item.phone, '', this.toDistance(item.distance)].join('\n'),
                    link: {
                        title: this.translocoService.translate('pgAppliancesSearch.result.openInGoogleMaps'),
                        href: `https://www.google.de/maps/search/?api=1&query=${mapsQuery}`,
                    },
                };
            }),
            totalCount: resource.attributes.data.total_items,
        };
    }

    private toDistance(value: string): string | undefined {
        const distance = parseInt(value, 10);

        if (isNaN(distance)) {
            return undefined;
        }

        /**
         * We call .translate() as sync at this position, because the upper component has been loaded by the pgAppliancesSearch context already
         */
        return this.translocoService.translate('pgAppliancesSearch.result.distanceTo', {
            distance: (distance / FACTOR_THOUSAND).toLocaleString('de-DE'),
        });
    }
}
