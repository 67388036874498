import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { type UiCardImageModel, type UiCardModel } from '@big-direkt/ui/card';
import { type CardFilterableResource } from './card-filterable.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class CardFilterableMapper extends ResourceMapper<UiCardModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigCardFilterable;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: CardFilterableResource, collection: ResourceCollection): UiCardModel {
        const card: UiCardModel = {
            title: resource.attributes.title,
            text: resource.attributes.text ?? undefined,
            svgIcon: resource.relationships.icon.data?.meta?.uri,
        };

        // eslint-disable-next-line no-null/no-null
        if (resource.relationships.image.data !== null) {
            (card as UiCardImageModel).image = this.resourceIdentifierMapper.map(resource.relationships.image.data, collection);
        }

        // eslint-disable-next-line no-null/no-null
        if (resource.attributes.link !== null) {
            card.link = {
                title: resource.attributes.link.title ?? '',
                href: resource.attributes.link.uri ?? '',
            };
        }

        return card;
    }
}
