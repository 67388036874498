import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigPersonalDataMapper } from '../mappers/node-big-personal-data.mapper';

export const provideNodeBigPersonalData = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigPersonalDataMapper.type],
            useClass: NodeBigPersonalDataMapper,
        },
    ]);
