/* eslint-disable @typescript-eslint/naming-convention */
import { type JsonLdModel } from '@big-direkt/utils/environment';

export const jsonLd: JsonLdModel = {
    organization: {
        name: 'BIG direkt gesund',
        url: 'https://www.big-direkt.de',
        logo: 'https://www.big-direkt.de/assets/logo.svg',
    },
    potentialAction: {
        '@type': 'SearchAction',
        target: 'https://www.big-direkt.de/suche?q={search_term_string}',
        'query-input': 'required name=search_term_string',
    },
    contactPoint: {
        telephone: '(+49) 0800 54565456',
        contactType: 'customer service',
        email: 'info@big-direkt.de',
    },
    address: {
        streetAddress: 'Rheinische Straße 1',
        postalCode: '44137',
        addressLocality: 'Dortmund',
    },
    sameAs: [
        'https://www.facebook.com/BIG',
        'https://twitter.com/BIG',
        'https://www.youtube.com/user/big',
    ],
    owns: {
        name: 'BIG App',
        // eslint-disable-next-line max-len
        description: 'Mit der kostenlosen BIG-App gibt es den Nachschlage- wie Ratgeberservice und die persönliche Geschäftsstelle jetzt auch für unterwegs. Als Direktkrankenkasse werden wir die BIG-App auch in Zukunft kontinuierlich ergänzen.',
        itemListOrder: 'https://schema.org/ItemListOrderAscending',
        itemListElement: [
            {
                name: 'BIG App',
                applicationCategory: 'Mobile App',
                installUrl: 'https://play.google.com/store/apps/details?id=com.bigdirekt&feature=search_result#?t=W251bGwsMSwyLDEsImNvbS5iaWdkaXJla3QiXQ',
                operatingSystem: 'Android',
                url: 'https://www.big-direkt.de/services/big_app.html',
                aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue: '3.5',
                    ratingCount: '142',
                },
                position: 2,
            },
            {
                name: 'BIG App',
                applicationCategory: 'Mobile App',
                installUrl: 'https://itunes.apple.com/de/app/big-direkt/id426961160?mt=8&amp;ls=1',
                operatingSystem: 'iOS',
                url: 'https://www.big-direkt.de/services/big_app/immer_dabei_die_big_app.html',
                aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue: '4',
                    ratingCount: '35',
                },
                position: 1,
            },
        ],
    },
};
