import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type AliveButtonModel } from './alive-button.model';
import { type AliveButtonResource } from './alive-button.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class AliveButtonMapper extends ResourceMapper<AliveButtonModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigAliveButton;

    public map(resource: AliveButtonResource): AliveButtonModel {
        return {
            type: ParagraphTypes.AliveButton,
            title: resource.attributes.title,
        };
    }
}
