import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService as JsonApiMapperInjectorService, ResourceTypes as JsonApiResourceTypes } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { MapperInjectorService as RestApiMapperInjectorService, ResourceTypes as RestApiResourceTypes, RESOURCE_PATH_TOKEN } from '@big-direkt/rest-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { AidsMapper } from './mapper/aids.mapper';
import { AppliancesSearchMapper } from './mapper/appliances-search.mapper';
import { LocationsMapper } from './mapper/locations.mapper';
import { ResultsMapper } from './mapper/results.mapper';
import { TopAidsMapper } from './mapper/top-aids.mapper';

export const provideAppliancesSearchParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: RestApiMapperInjectorService.tokens[RestApiResourceTypes.AppliancesSearchAids],
            useClass: AidsMapper,
        },
        {
            provide: JsonApiMapperInjectorService.tokens[JsonApiResourceTypes.ParagraphBigAppliancesSearch],
            useClass: AppliancesSearchMapper,
        },
        {
            provide: RestApiMapperInjectorService.tokens[RestApiResourceTypes.AppliancesSearchLocation],
            useClass: LocationsMapper,
        },
        {
            provide: RestApiMapperInjectorService.tokens[RestApiResourceTypes.AppliancesSearchResults],
            useClass: ResultsMapper,
        },
        {
            provide: RestApiMapperInjectorService.tokens[RestApiResourceTypes.AppliancesSearchTopAids],
            useClass: TopAidsMapper,
        },
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [RestApiResourceTypes.AppliancesSearchAids]: '/appliances-search/aids' },
            multi: true,
        },
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [RestApiResourceTypes.AppliancesSearchLocation]: '/api/service-tools/api/location' },
            multi: true,
        },
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [RestApiResourceTypes.AppliancesSearchResults]: '/appliances-search/results' },
            multi: true,
        },
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [RestApiResourceTypes.AppliancesSearchTopAids]: '/appliances-search/top10' },
            multi: true,
        },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./appliances-search.component'),
                type: ParagraphTypes.AppliancesSearch,
            },
            multi: true,
        },
    ]);
