import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { SpecialistSearchSpecialistsModel } from '../models/specialist-search-specialists.model';
import { SpecialistSearchSpecialistsResource } from '../models/specialist-search-specialists.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class SpecialistSearchSpecialistsMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.SpecialistSearchSpecialists;

    public map(resource: SpecialistSearchSpecialistsResource): SpecialistSearchSpecialistsModel[] {
        return resource.attributes;
    }
}
