import { Injectable } from '@angular/core';
import {
    JsonApiResourceMapper,
    type ResourceCollection,
    ResourceIdentifierMapperService,
    ResourceMapper,
    ResourceTypes,
} from '@big-direkt/json-api-client';
import { type NodeModel, NodeTypes } from '@big-direkt/utils/shared';
import { type NodeBigStartPageResource } from '../resources/node-big-start-page.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigStartPageMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeBigStartPage;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeBigStartPageResource, collection: ResourceCollection): NodeModel {
        return {
            type: NodeTypes.Home,
            nodeId: resource.attributes.node_id ?? 0,
            pageTitle: {
                homePageCard: {
                    label: resource.attributes.campaign_teaser_title ?? undefined,
                    content: resource.attributes.campaign_teaser_content ?? undefined,
                    linkTitle: resource.attributes.campaign_teaser_link?.title,
                    linkUrl: resource.attributes.campaign_teaser_link?.uri,
                },
                picture: this.resourceIdentifierMapper.map(resource.relationships.image_introduction.data ?? undefined, collection),
            },
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
            metadata: resource.attributes.metatag ?? [],
            breadcrumbs: undefined,
        };
    }
}
