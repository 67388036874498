import { inject, Injectable } from '@angular/core';
import {
    JsonApiResourceMapper,
    MediaFileIdentifier,
    ResourceCollection,
    ResourceIdentifierMapperService,
    ResourceMapper,
    ResourceTypes,
} from '@big-direkt/json-api-client';
import { type QuickLinkListItemModel } from '@big-direkt/ui/quick-link-list';
import { QuickLinkDownloadResource } from './quick-link-download.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class QuickLinkLinkDownloadMapper extends ResourceMapper<QuickLinkListItemModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphDPPQuickLinkDownloadLink;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: QuickLinkDownloadResource, collection: ResourceCollection): QuickLinkListItemModel {
        const mediaFile = this.resourceIdentifierMapper.map<MediaFileIdentifier>(resource.relationships.file.data ?? undefined, collection)


        return {
            downloadType: resource.attributes.download_type,
            icon: resource.relationships.icon.data?.meta?.uri,
            organizations: resource.attributes.organizations,
            title: mediaFile?.meta.title ?? '',
            url: mediaFile?.meta.uri ?? '',
            text: mediaFile?.meta.text,
            file: {
                size: mediaFile?.meta.size ?? undefined,
                title: mediaFile?.meta.title ?? undefined,
                type: mediaFile?.meta.type ?? undefined,
                uri: mediaFile?.meta.uri ?? undefined,
            },
        };
    }
}
