import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type CardGroupModel } from './card-group.model';
import { type FilterableCardGroupResource } from './filterable-card-group.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class FilterableCardGroupMapper extends ResourceMapper<CardGroupModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigFilterableCardGroup;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: FilterableCardGroupResource, collection: ResourceCollection): CardGroupModel {
        return {
            type: ParagraphTypes.CardGroup,
            rendering: resource.attributes.card_group_rendering,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            items: this.resourceIdentifierMapper.mapMultiple(resource.relationships.card_group_card.data, collection)!,
        };
    }
}
