import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { AutocompleteOption } from '@big-direkt/ui/input';
import { SpecialistSearchSpecialityResource } from '../models/specialist-search-speciality.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class SpecialistSearchSpecialityMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.SpecialistSearchSpeciality;

    public map(resource: SpecialistSearchSpecialityResource): AutocompleteOption<string>[] {
        return resource.attributes.map(speciality => ({
            label: speciality.title,
            key: speciality.title,
            children: speciality.entries.map(child => ({
                ...child,
                key: child.value,
            })),
        }));
    }
}
