import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService, ResourceTypes } from '@big-direkt/json-api-client';
import { AccordionMapper } from './accordion.mapper';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';

export const provideAccordionParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[ResourceTypes.ParagraphAccordion],
            useClass: AccordionMapper,
        },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./accordion.component'),
                type: ParagraphTypes.Accordion,
            },
            multi: true,
        },
    ]);
