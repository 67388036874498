import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { SERVICE_TOOLS_MAP, type ServiceToolsMapItem } from '@big-direkt/service-tools/base';
import type FamilyHealthRetreatSearchComponent from '../components/family-health-retreat-search.component';
import { FamilyHealthRetreatSearchMapper } from '../mappers/family-health-retreat-search.mapper';

export const provideFamilyHealthRetreatSearchServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [ResourceTypes.FamilyHealthRetreatSearchData]: 'api/service-tool/family-health-retreat-search/facilities' },
            multi: true,
        },
        {
            provide: MapperInjectorService.tokens[ResourceTypes.FamilyHealthRetreatSearchData],
            useClass: FamilyHealthRetreatSearchMapper,
        },
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/family-health-retreat-search.component'),
                id: 'family-health-retreat-search',
            } as ServiceToolsMapItem<FamilyHealthRetreatSearchComponent>,
            multi: true,
        },
    ]);
