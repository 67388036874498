import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { ParagraphTypes, type LinkList } from '@big-direkt/utils/shared';
import { type LinkListResource } from './link-list.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class LinkListMapper extends ResourceMapper<LinkList> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigLinkList;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: LinkListResource, collection: ResourceCollection): LinkList {
        const linkList: LinkList = {
            type: ParagraphTypes.LinkList,
            heading: resource.attributes.heading,
            title: resource.attributes.title,
            items: this.resourceIdentifierMapper.mapMultiple(resource.relationships.links.data, collection) ?? [],
        };

        // eslint-disable-next-line no-null/no-null
        if (resource.relationships.image.data !== null) {
            linkList.image = this.resourceIdentifierMapper.map(resource.relationships.image.data, collection);
        }

        return linkList;
    }
}
