import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { type UiCardImageModel } from '@big-direkt/ui/card';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type CardModel } from './card.model';
import { type CardResource } from './card.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class CardMapper extends ResourceMapper<CardModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigCard;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: CardResource, collection: ResourceCollection): CardModel {
        const card: CardModel = {
            type: ParagraphTypes.Card,
            title: resource.attributes.title,
            text: resource.attributes.text ?? undefined,
            svgIcon: resource.relationships.icon.data?.meta?.uri,
        };

        // eslint-disable-next-line no-null/no-null
        if (resource.relationships.image.data !== null) {
            (card as UiCardImageModel).image = this.resourceIdentifierMapper.map(resource.relationships.image.data, collection);
        }

        // eslint-disable-next-line no-null/no-null
        if (resource.attributes.link !== null) {
            card.link = {
                title: resource.attributes.link.title ?? '',
                href: resource.attributes.link.uri ?? '',
            };
        }

        return card;
    }
}
