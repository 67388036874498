import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { LinkModel } from './link.model';
import { LinkResource } from './link.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class LinkMapper extends ResourceMapper<LinkModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigLink;

    public constructor() {
        super();
    }

    public map(resource: LinkResource): LinkModel {
        return {
            type: ParagraphTypes.Link,
            link: {
                href: resource.attributes.link?.uri ?? '',
                title: resource.attributes.link?.title,
            },
        };
    }
}
