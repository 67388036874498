import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { LinkItemMapper } from './link-item.mapper';
import { LinkListMapper } from './link-list.mapper';
import { ParagraphTypes } from '@big-direkt/utils/shared';

export const provideLinkListParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[LinkListMapper.type], useClass: LinkListMapper },
        { provide: MapperInjectorService.tokens[LinkItemMapper.type], useClass: LinkItemMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./link-list.component'),
                type: ParagraphTypes.LinkList,
            },
            multi: true,
        },
    ]);
