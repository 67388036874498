import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { BigtionaerMapper } from './bigtionaer.mapper';
import { MapperInjectorService, ResourceTypes } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';

export const provideBigtionaerParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[ResourceTypes.ParagraphBigBigtionaer],
            useClass: BigtionaerMapper,
        },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./bigtionaer.component'),
                type: ParagraphTypes.Bigtionaer,
            },
            multi: true,
        },
    ]);
