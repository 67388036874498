import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'big-ui-footer',
    templateUrl: './footer.component.html',
    standalone: true,
})
export class FooterComponent {
    @HostBinding('class') public classList = 'block';
}
