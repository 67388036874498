import { Injectable } from '@angular/core';
import {
    JsonApiResourceMapper,
    ResourceIdentifierMapperService,
    ResourceMapper,
    ResourceTypes,
    type ResourceCollection,
} from '@big-direkt/json-api-client';
import { NodeTypes, type NodeModel } from '@big-direkt/utils/shared';
import { NodeDPPStartPageResource } from '../resources/node-dpp-start-page.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeDPPStartPageMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeDPPStartPage;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeDPPStartPageResource, collection: ResourceCollection): NodeModel {
        return {
            type: NodeTypes.DPPHome,
            nodeId: resource.attributes.node_id ?? 0,
            heading: resource.attributes.headline_h1 ?? undefined,
            introduction: resource.attributes.plain_text_introduction ?? undefined,
            pageTitle: {
                homePageCard: {
                    label: resource.attributes.campaign_teaser_title ?? undefined,
                    content: resource.attributes.campaign_teaser_content ?? undefined,
                    linkTitle: resource.attributes.campaign_teaser_link?.title,
                    linkUrl: resource.attributes.campaign_teaser_link?.uri,
                },
                picture: this.resourceIdentifierMapper.map(resource.relationships.image_introduction.data ?? undefined, collection),
            },
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
            metadata: resource.attributes.metatag ?? [],
            breadcrumbs: undefined,
        };
    }
}
