import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type CookiebotModel } from './cookiebot.model';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class CookiebotMapper extends ResourceMapper<CookiebotModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigCookiebot;

    public map(): CookiebotModel {
        return {
            type: ParagraphTypes.Cookiebot,
        };
    }
}
