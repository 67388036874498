import { Injectable } from '@angular/core';
import { type QuickLinkModel } from '@big-direkt/utils/shared';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeQuickLinkResource } from '../resources/node-quick-link.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeQuickLinkMapper extends ResourceMapper<QuickLinkModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeQuickLink;

    public map(resource: NodeQuickLinkResource): QuickLinkModel {
        return {
            nodeId: resource.attributes.node_id ?? 0,
            icon: resource.relationships?.icon?.data?.meta?.uri,
            title: resource.attributes.title,
            text: resource.attributes.description,
            url: resource.attributes.url.uri,
        };
    }
}
