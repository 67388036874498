import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type ServiceToolModel } from './service-tool.model';
import { type ServiceToolResource } from './service-tool.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class ServiceToolMapper extends ResourceMapper<ServiceToolModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphServiceTool;

    public map(resource: ServiceToolResource): ServiceToolModel {
        return {
            type: ParagraphTypes.ServiceTool,
            id: resource.attributes.service_tool,
            title: resource.attributes.title,
            introduction: resource.attributes.text_long_unformatted,
            accordionTitle: resource.attributes.accordion_title,
            displayInAccordion: resource.attributes.accordion_show,
        };
    }
}
