import { Injectable, inject } from '@angular/core';
import { JsonApiResourceMapper, ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { UserDetailsItem } from './user-details-item.model';
import { UserDetailsModel } from './user-details.model';
import { UserDetailsResource } from './user-details.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class UserDetailsMapper extends ResourceMapper<UserDetailsModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigUserDetails;
    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: UserDetailsResource, collection: ResourceCollection): UserDetailsModel {
        const items: UserDetailsItem[] = this.resourceIdentifierMapper.mapMultiple(resource.relationships.items.data, collection) ?? [];

        return {
            type: ParagraphTypes.UserDetails,
            title: resource.attributes.title,
            icon: resource.relationships.icon.data?.meta?.uri,
            allowedGroups: resource.attributes.role_condition,
            hasEditLinks: this.hasEditLinks(items),
            items,
            ...(!!resource.attributes.link && {
                link: {
                    title: resource.attributes.link.title || undefined,
                    href: resource.attributes.link.url,
                },
            }),
        };
    }

    private hasEditLinks(items: UserDetailsItem[]): boolean {
        return !!items.some(item => item.link);
    }
}
