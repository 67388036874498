import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type SubjectAreasResource } from './subject-areas.resource';
import { type SubjectAreaTerm } from './subject-area-term.model';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class SubjectAreasMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.SubjectAreas;

    public map(resource: SubjectAreasResource): SubjectAreaTerm[] {
        return resource.attributes.rows.map(area => ({
            id: parseInt(area.id, 10),
            // TODO: remove this when Drupal REST views do not overwrite &- with &amp;-chars
            name: area.name.replaceAll('&amp;', '&'),
            parent: parseInt(area.parent, 10),
        }));
    }
}
