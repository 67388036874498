import { inject, Injectable } from '@angular/core';
import {
    JsonApiResourceMapper,
    type ResourceCollection,
    ResourceIdentifierMapperService,
    ResourceMapper,
    ResourceTypes,
} from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type VideoGroupModel } from './video-group.model';
import { type VideoGroupResource } from './video-group.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class VideoGroupMapper extends ResourceMapper<VideoGroupModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphVideoGroup;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: VideoGroupResource, collection: ResourceCollection): VideoGroupModel {
        return {
            type: ParagraphTypes.VideoGroup,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            items: this.resourceIdentifierMapper.mapMultiple(resource.relationships.card_group_card.data, collection)!,
        };
    }
}
