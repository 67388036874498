import { inject, Injectable } from '@angular/core';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type ParagraphAccordionModel } from '@big-direkt/utils/shared';
import { type AccordionResource } from './accordion.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class AccordionMapper extends ResourceMapper<ParagraphAccordionModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphAccordion;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: AccordionResource, collection: ResourceCollection): ParagraphAccordionModel {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.resourceIdentifierMapper.map(resource.relationships.accordion_node.data, collection)!;
    }
}
