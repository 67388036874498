import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService as JsonApiMapperInjectorService } from '@big-direkt/json-api-client';
import { MapperInjectorService as RestApiMapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ImageCreditsResultsMapper } from './image-credits-results.mapper';
import { ImageCreditsMapper } from './image-credits.mapper';
import { ParagraphTypes } from '@big-direkt/utils/shared';

export const provideImageCreditsParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.ImageCreditsResults]: '/api/image-credits' }, multi: true },
        { provide: JsonApiMapperInjectorService.tokens[ImageCreditsMapper.type], useClass: ImageCreditsMapper },
        { provide: RestApiMapperInjectorService.tokens[ImageCreditsResultsMapper.type], useClass: ImageCreditsResultsMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./image-credits.component'),
                type: ParagraphTypes.ImageCredits,
            },
            multi: true,
        },
    ]);
