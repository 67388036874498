import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { SERVICE_TOOLS_MAP, type ServiceToolsMapItem } from '@big-direkt/service-tools/base';
import type VaccinationTravelComponent from '../components/vaccination-travel.component';
import { VaccinationTravelCountriesMapper } from '../mappers/vaccination-travel-countries.mapper';
import { VaccinationTravelCountryMapper } from '../mappers/vaccination-travel-country.mapper';
import { VaccinationTravelYellowFeverCountriesMapper } from '../mappers/vaccination-travel-yellow-fever-countries-mapper';

export const provideVaccinationTravelServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [ResourceTypes.VaccinationTravelCountries]: 'api/service-tool/vaccination-travel/countries/' },
            multi: true,
        },
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [ResourceTypes.VaccinationTravelCountry]: 'api/service-tool/vaccination-travel/country/' },
            multi: true,
        },
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [ResourceTypes.VaccinationTravelYellowFeverCountries]: 'api/service-tool/vaccination-travel/yellow-fever-countries/' },
            multi: true,
        },
        {
            provide: MapperInjectorService.tokens[ResourceTypes.VaccinationTravelCountry],
            useClass: VaccinationTravelCountryMapper,
        },
        {
            provide: MapperInjectorService.tokens[ResourceTypes.VaccinationTravelCountries],
            useClass: VaccinationTravelCountriesMapper,
        },
        {
            provide: MapperInjectorService.tokens[ResourceTypes.VaccinationTravelYellowFeverCountries],
            useClass: VaccinationTravelYellowFeverCountriesMapper,
        },
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/vaccination-travel.component'),
                id: 'vaccination-travel',
            } as ServiceToolsMapItem<VaccinationTravelComponent>,
            multi: true,
        },
    ]);
