import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type WebformModel } from './webform.model';
import { type WebformResource } from './webform.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class WebformMapper extends ResourceMapper<WebformModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphWebform;

    public map(resource: WebformResource): WebformModel {
        return {
            type: ParagraphTypes.Webform,
            id: resource.relationships.webform.data.meta.webform_id,
            showPageFrame: !!resource.attributes?.show_page_frame,
        };
    }
}
