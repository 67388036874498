import { Injectable } from '@angular/core';
import { type NodeModel, NodeTypes } from '@big-direkt/utils/shared';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeBigMyBigResource } from '../resources/node-big-mybig.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigMyBigMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeBigMyBig;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeBigMyBigResource, collection: ResourceCollection): NodeModel {
        return {
            type: NodeTypes.MyBig,
            nodeId: resource.attributes.node_id ?? 0,
            breadcrumbs: resource.attributes.breadcrumbs ?? [],
            heading: resource.attributes.headline_h1 ?? resource.attributes.title,
            introduction: resource.attributes.plain_text_introduction,
            metadata: resource.attributes.metatag ?? [],
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
        };
    }
}
