import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { CardFilterableMapper } from './card-filterable.mapper';
import { CardGroupMapper } from './card-group.mapper';
import { CardMapper } from './card.mapper';
import { FilterableCardGroupMapper } from './filterable-card-group.mapper';

export const provideCardGroupParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[CardGroupMapper.type], useClass: CardGroupMapper },
        { provide: MapperInjectorService.tokens[FilterableCardGroupMapper.type], useClass: FilterableCardGroupMapper },
        { provide: MapperInjectorService.tokens[CardFilterableMapper.type], useClass: CardFilterableMapper },
        { provide: MapperInjectorService.tokens[CardMapper.type], useClass: CardMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./card-group.component'),
                type: ParagraphTypes.CardGroup,
            },
            multi: true,
        },
    ]);
