import { type DataLayerItem } from './models/data-layer-item';
import { type Config } from './models/config';

// eslint-disable-next-line import/prefer-default-export
export class BigGtmTracking {
    private readonly defaultConfig: Config = {
        dataLayer: [],
        bindDataEventsOnInit: false,
    };

    private readonly allowedAttributes: Record<string, string> = {
        gtmEvent: 'event',
        gtmEventAction: 'eventAction',
        gtmEventCategory: 'eventCategory',
        gtmEventLabel: 'eventLabel',
        gtmEventInteractionType: 'interactionType',
        gtmEventModuleName: 'moduleName',
        gtmEventRequired: 'required',
        gtmEventStepName: 'stepName',
        gtmEventTargetUrl: 'targetUrl',
        gtmEventValue: 'value',
    };

    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private config: Config;

    constructor(
        private readonly document: Document,
        config: Config,
    ) {
        this.config = { ...this.defaultConfig, ...config };

        if (this.config.bindDataEventsOnInit) {
            this.bindDataAttributeEvents();
        }
    }

    /**
     * Pushs a given event to the gtm dataLayer list
     * @param item
     */
    public pushToDataLayer(item: DataLayerItem): void {
        this.config.dataLayer.push(item);
    }

    /**
     * Bind events to a given DOM selector
     *
     * ```typescript
     * BigGtmTracking.registerElement('.css-dom-selector', ['click', 'focus'], {customProperty: 'test'})
     * ```
     * @param selector
     * @param browserEventType
     * @param dataLayerItem
     */
    public registerElement(selector: string, browserEventType: string[] | string, dataLayerItem: DataLayerItem): void {
        const elements: NodeListOf<Element> = this.document.querySelectorAll(selector);
        const browserEventTypes: string[] = Array.isArray(browserEventType) ? browserEventType : [browserEventType];

        Array.from(elements).forEach((element: Element) => {
            browserEventTypes.forEach((customEvent: string) => {
                // Create new object reference
                const item: DataLayerItem = { ...dataLayerItem };

                this.bindEventListener(customEvent, element, item);
            });
        });
    }

    /**
     * Checks the dataset of a HTML element and enriches the gtm event with it's data
     * @param element
     * @param dataLayerItem
     */
    private enrichFromDataset(element: HTMLOrSVGElement, dataLayerItem: DataLayerItem): DataLayerItem {
        Object.keys(element.dataset).forEach((attribute: string) => {
            if (typeof this.allowedAttributes[attribute] !== 'undefined') {
                const eventKey: string = this.allowedAttributes[attribute];
                // eslint-disable-next-line no-param-reassign
                dataLayerItem[eventKey] = element.dataset[attribute];
            }
        });

        return dataLayerItem;
    }

    /**
     * Binds events to all given elements that hold the data-gtm attribute
     * Default binds to click event
     */
    private bindDataAttributeEvents(): void {
        const elements: NodeListOf<Element> = this.document.querySelectorAll('[data-gtm]');

        Array.from(elements).forEach((element: Element) => {
            const target: HTMLOrSVGElement = element as unknown as HTMLOrSVGElement;
            const browserEvents: string[] = this.getBrowserEvents(target);

            browserEvents.forEach((browserEvent: string) => {
                this.bindEventListener(browserEvent, element, {
                    event: 'unknown',
                });
            });
        });
    }

    /**
     * Return a list of Browser Events
     * The default event is a click
     * @param target
     */
    // eslint-disable-next-line class-methods-use-this
    private getBrowserEvents(target: HTMLOrSVGElement): string[] {
        return target.dataset.gtmBrowserEvents !== undefined ? target.dataset.gtmBrowserEvents.split(',').map((str: string) => str.trim()) : ['click'];
    }

    /**
     * Binds an event listener to the given element
     * @param event
     * @param element
     * @param item
     */
    private bindEventListener(event: string, element: Element, item: DataLayerItem): void {
        element.addEventListener(event, () => {
            const enrichedDataLayerItem: DataLayerItem = this.enrichFromDataset(element as unknown as HTMLOrSVGElement, item);

            this.pushToDataLayer(enrichedDataLayerItem);
        });
    }
}
