import { type SubjectAreaNodeModel } from './subject-area-node.model';
import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type SubjectAreaNodesModel } from './subject-area-nodes.model';
import { type SubjectAreaNodesResource } from './subject-area-nodes.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class SubjectAreaNodesMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.SubjectAreaNodes;

    public map(resource: SubjectAreaNodesResource): SubjectAreaNodesModel {
        const rows: SubjectAreaNodeModel[] = resource.attributes.rows.map(
            (row): SubjectAreaNodeModel => ({
                title: row.title,
                description: row.description,
                subjectAreas: row.subject_areas.map(area => ({ id: parseInt(area.id, 10), name: area.name })),
                url: row.url,
                image: {
                    // TODO: remove this when Drupal REST views do not overwrite &-chars with &amp;-chars
                    url: row.image.replaceAll('&amp;', '&'),
                    alt: row.alt,
                },
            }),
        );

        return {
            pager: {
                totalPages: resource.attributes.pager.total_pages,
                totalItems: parseInt(resource.attributes.pager.total_items, 10),
                itemsPerPage: resource.attributes.pager.items_per_page,
                currentPage: resource.attributes.pager.current_page,
            },
            rows,
        };
    }
}
