import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type InsuranceServiceGroupModel } from '../models/insurance-service-group.model';
import { type InsuranceServiceModel } from '../models/insurance-service.model';
import { type InsuranceServicesNodesResource } from '../models/insurance-services-nodes.resource';
import { replaceUmlauts } from '../utils/replace-umlauts.util';

export const LETTERS_IN_ALPHABET = 26;
export const CHAR_CODE_A = 65;
export const CHAR_CODE_LOWER_A = 97;

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class InsuranceServicesNodesMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.InsuranceServicesNodes;

    private get emptyGroups(): InsuranceServiceGroupModel[] {
        return Array.from(Array(LETTERS_IN_ALPHABET)).map((_: number, index: number) => ({
            key: String.fromCharCode(CHAR_CODE_A + index),
            items: [],
        }));
    }

    public map(resource: InsuranceServicesNodesResource): InsuranceServiceGroupModel[] {
        return this.groupItems(this.mapToInsuranceServiceModels(resource.attributes.rows));
    }

    private groupItems(items: InsuranceServiceModel[]): InsuranceServiceGroupModel[] {
        return items.reduce((groups: InsuranceServiceGroupModel[], item: InsuranceServiceModel) => {
            groups[item.searchText.charCodeAt(0) - CHAR_CODE_LOWER_A]?.items?.push(item);

            return groups;
        }, this.emptyGroups);
    }

    private mapToInsuranceServiceModels(items: InsuranceServicesNodesResource['attributes']['rows']): InsuranceServiceModel[] {
        return items.map((item: InsuranceServicesNodesResource['attributes']['rows'][number]): InsuranceServiceModel => {
            // TODO: remove this when Drupal REST views do not overwrite &-chars with &amp;-chars
            const text = item.title.replaceAll('&amp;', '&').replaceAll('&quot;', '"');

            return {
                searchText: replaceUmlauts(text),
                isAdditionalBenefit: item.additional_benefit,
                href: item.url,
                text,
            };
        });
    }
}
