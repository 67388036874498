import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes, type LinkListItem } from '@big-direkt/utils/shared';
import { type LinkItemResource } from './link-item.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class LinkItemMapper extends ResourceMapper<LinkListItem> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigLinkItem;

    public map(resource: LinkItemResource): LinkListItem {
        return {
            type: ParagraphTypes.LinkListItem,
            href: resource.attributes.link.uri,
            title: resource.attributes.link.title,
            icon: resource.relationships.icon.data?.meta?.uri,
            introduction: resource.attributes.link.introduction,
        };
    }
}
