/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    EventBusService,
    type TrackingDatalayerInitializeEvent,
    type TrackingDatalayerResetEvent,
    type TrackingFormPageEvent,
    type TrackingFormsFieldChangeEvent,
    type TrackingFormsFieldCorrectionEvent,
    type TrackingFormsFieldDropoffEvent,
    type TrackingFormsFileUploadEvent,
    type TrackingFormsFormAbandonmentEvent,
    type TrackingFormsFormInitializeEvent,
    type TrackingFormsFormSubmitEvent,
    type TrackingFormsInfoIconEvent,
    type TrackingFormsNextStepEvent,
    type TrackingFormsPreviousStepEvent,
    type TrackingInternalKeywordSearchEvent,
    type TrackingInternalSearchResultClickEvent,
    type TrackingPageInitializedEvent,
    type TrackingPageNavigationEvent,
    type TrackingServiceToolFieldChangeEvent,
    type TrackingServiceToolFieldCorrectionEvent,
    type TrackingServiceToolFormSubmitEvent,
    type TrackingServiceToolImpressionEvent,
    type TrackingServiceToolInfoIconEvent,
    type TrackingServiceToolPopupClickEvent,
    type TrackingServiceToolResultClickEvent,
    type TrackingServiceToolResultInfoIconClickEvent,
    type TrackingServiceToolShowOrHideContentEvent,
} from '@big-direkt/event-bus';
import { type FormHistoryItem } from '../models/form-history-item';
import { TrackingService } from './tracking.service';

@Injectable({
    providedIn: 'root',
})
export class TrackingEventHandlerService {
    private readonly eventBus = inject(EventBusService);
    private readonly trackingService = inject(TrackingService);
    private readonly destroyRef = inject(DestroyRef);
    private isInitialized = false;

    public initializeEvents(): void {
        if (this.isInitialized) {
            return;
        }

        this.handleDatalayerInitializeEvent();
        this.handleDatalayerResetEvent();

        this.handleFormPageEvent();

        this.handleFileUploadEvent();
        this.handleFieldChangeEvent();
        this.handleFieldCorrectionEvent();
        this.handleFieldDropoffEvent();
        this.handleFormInitializeEvent();
        this.handleFormAbandonmentEvent();
        this.handleFormSubmitEvent();
        this.handleFormNextStepEvent();
        this.handleFormPreviousStepEvent();
        this.handleFormInfoIconEvent();

        this.handleInternalKeywordSearchEvent();
        this.handleInternalSearchResultClickEvent();

        this.handlePageInitializedEvent();
        this.handlePageNavigationEvent();

        this.handleServiceToolFieldChangeEvent();
        this.handleServiceToolFieldCorrectionEvent();
        this.handleServiceToolInfoIconEvent();
        this.handleServiceToolFormSubmitEvent();
        this.handleServiceToolImpressionEvent();
        this.handleServiceToolPopupClickClickEvent();
        this.handleServiceToolResultClick();
        this.handleServiceToolResultInfoIconEvent();
        this.handleServiceToolShowOrHideContentEvent();

        this.isInitialized = true;
    }

    private handleDatalayerInitializeEvent(): void {
        this.eventBus.on<TrackingDatalayerInitializeEvent>('datalayer_initialize_event').subscribe(() => {
            this.trackingService.initialize();
        });
    }

    private handleDatalayerResetEvent(): void {
        this.eventBus
            .on<TrackingDatalayerResetEvent>('datalayer_reset_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.trackingService.resetDataLayer();
            });
    }

    private handleFormPageEvent(): void {
        this.eventBus
            .on<TrackingFormPageEvent>('form_page_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormPageEvent) => {
                this.trackingService.trackFormPageEvent({
                    formId: event.data.formId ?? 'unknown',
                    formTitle: event.data.formTitle ?? 'unknown',
                    stepName: event.data.stepName ?? 'unknown',
                });
            });
    }

    private handleFileUploadEvent(): void {
        this.eventBus
            .on<TrackingFormsFileUploadEvent>('forms_file_upload_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormsFileUploadEvent) => {
                this.trackingService.trackFormEvent({
                    eventAction: 'file_upload',
                    eventLabel: event.data.validationResult,
                    stepName: event.data.stepName,
                    fieldName: event.data.fieldName,
                    fileType: event.data.fileType,
                    fileSize: event.data.fileSize,
                    eventDetail: event.data.errorMessage,
                    required: event.data.required,
                });
            });
    }

    private handleFieldChangeEvent(): void {
        this.eventBus
            .on<TrackingFormsFieldChangeEvent>('forms_field_change_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormsFieldChangeEvent) => {
                this.trackingService.trackFormEvent({
                    eventAction: 'field_change',
                    eventLabel: 'changed',
                    stepName: event.data.stepName,
                    required: event.data.required,
                    fieldName: event.data.fieldName,
                    triggerElement: event.data.triggerElement,
                    duration: event.data.duration,
                });

                this.trackingService.registerFormHistoryStep(event.data.formId, event.data.fieldId, event.data.fieldName, event.data.required);
            });
    }

    private handleFieldCorrectionEvent(): void {
        this.eventBus
            .on<TrackingFormsFieldCorrectionEvent>('forms_field_correction_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormsFieldCorrectionEvent) => {
                this.trackingService.trackFormEvent({
                    eventAction: 'field_correction',
                    eventLabel: 'changed',
                    stepName: event.data.stepName,
                    required: event.data.required,
                    fieldName: event.data.fieldName,
                    triggerElement: event.data.triggerElement,
                    duration: event.data.duration,
                });
            });
    }

    private handleFieldDropoffEvent(): void {
        this.eventBus
            .on<TrackingFormsFieldDropoffEvent>('forms_field_dropoff_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormsFieldDropoffEvent) => {
                const lastHistoryStep: FormHistoryItem | undefined = this.trackingService.getLastFormHistoryStep(event.data.webformId);

                this.trackingService.trackFormEvent({
                    eventAction: 'field_dropoff',
                    eventLabel: lastHistoryStep === undefined ? 'no input' : 'input',
                    stepName: event.data.stepName,
                    fieldName: lastHistoryStep === undefined ? '' : lastHistoryStep.fieldTitle,
                    required: lastHistoryStep === undefined ? '' : lastHistoryStep.required,
                });
            });
    }

    private handleFormInitializeEvent(): void {
        this.eventBus
            .on<TrackingFormsFormInitializeEvent>('forms_form_initialize_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormsFormInitializeEvent) => {
                this.trackingService.initializeFormHistory(event.data.webformId);
            });
    }

    private handleFormAbandonmentEvent(): void {
        this.eventBus.on<TrackingFormsFormAbandonmentEvent>('forms_form_abandonment_event').subscribe((event: TrackingFormsFormAbandonmentEvent) => {
            this.trackingService.trackFormEvent({
                eventAction: 'form_abandonment',
                eventLabel: this.trackingService.getPreparedFormHistoryStepLabels(event.data.webformId),
                historyIds: this.trackingService.getPreparedFormHistorySteps(event.data.webformId),
                targetUrl: event.data.targetUrl,
                triggerElement: event.data.triggerElement,
            });
        });
    }

    private handleFormSubmitEvent(): void {
        this.eventBus
            .on<TrackingFormsFormSubmitEvent>('forms_form_submit_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormsFormSubmitEvent) => {
                this.trackingService.trackFormEvent({
                    eventAction: 'form_submit',
                    eventLabel: event.data.validationResult,
                    eventDetail: event.data.errorMessage,
                    stepName: event.data.stepName,
                    targetStepName: event.data.targetStepName,
                    triggerElement: event.data.triggerElement,
                });
            });
    }

    private handleFormNextStepEvent(): void {
        this.eventBus
            .on<TrackingFormsNextStepEvent>('forms_next_step_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormsNextStepEvent) => {
                this.trackingService.trackFormEvent({
                    eventAction: 'next_step',
                    eventLabel: event.data.validationResult,
                    stepName: event.data.stepName,
                    targetStepName: event.data.targetStepName,
                    fieldName: event.data.fieldName,
                    triggerElement: event.data.triggerElement,
                });
            });
    }

    private handleFormPreviousStepEvent(): void {
        this.eventBus
            .on<TrackingFormsPreviousStepEvent>('forms_previous_step_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormsPreviousStepEvent) => {
                this.trackingService.trackFormEvent({
                    eventAction: 'previous_step',
                    eventLabel: event.data.triggerElementText,
                    stepName: event.data.stepName,
                    targetStepName: event.data.targetStepName,
                    triggerElement: event.data.triggerElement,
                });
            });
    }

    private handleInternalKeywordSearchEvent(): void {
        this.eventBus
            .on<TrackingInternalKeywordSearchEvent>('internal_keyword_search_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingInternalKeywordSearchEvent) => {
                this.trackingService.trackInternalSearchEvent({
                    eventAction: 'keyword_search',
                    entryType: event.data.entryType,
                    q: event.data.q,
                    cat: event.data.cat,
                    res: event.data.res,
                    src: event.data.src,
                });
            });
    }

    private handleInternalSearchResultClickEvent(): void {
        this.eventBus
            .on<TrackingInternalSearchResultClickEvent>('internal_search_result_click_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingInternalSearchResultClickEvent) => {
                this.trackingService.trackInternalSearchEvent({
                    eventAction: 'search_result_click',
                    clickRank: event.data.clickRank,
                    clickTarget: event.data.clickTarget,
                    page: event.data.page,
                });
            });
    }

    private handleFormInfoIconEvent(): void {
        this.eventBus
            .on<TrackingFormsInfoIconEvent>('forms_info_icon_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingFormsInfoIconEvent) => {
                this.trackingService.trackFormEvent({
                    eventAction: 'info_icon',
                    eventLabel: event.data.isActive ? 'Open' : 'Close',
                    stepName: event.data.stepName,
                    fieldName: event.data.fieldName,
                    required: event.data.required,
                    triggerElement: 'BIG-UI-INFO-BUTTON',
                });
            });
    }

    private handlePageInitializedEvent(): void {
        this.eventBus
            .on<TrackingPageInitializedEvent>('page_initialized_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingPageInitializedEvent) => {
                this.trackingService.trackEvent({
                    event: 'page_initialized',
                    envWork: event.data.envWork === 'prod' ? 'prod' : 'test',
                    envType: 'web',
                    siteName: event.data.siteName ?? '',
                    pageType: event.data.pageType,
                    pageCat1: event.data.pageCat1 ?? '',
                    pageCat2: event.data.pageCat2 ?? '',
                    pageCat3: event.data.pageCat3 ?? '',
                    pageCat4: event.data.pageCat4 ?? '',
                    pageKeywords: event.data.pageKeywords ?? '',
                    pageID: event.data.pageID || '',
                    loginStatus: event.data.isLoggedIn ? 'logged in' : 'logged out',
                    customerSegment: event.data.customerSegment,
                });
            });
    }

    private handlePageNavigationEvent(): void {
        this.eventBus
            .on<TrackingPageNavigationEvent>('page_navigation_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingPageNavigationEvent) => {
                const xPath: string = this.trackingService.getXpathForElement(event.data.triggerElement);

                this.trackingService.trackNavigationEvent({
                    eventAction: this.trackingService.getTargetLinkType(event.data.targetUrl),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    eventLabel: this.trackingService.getPageNavigationEventLabel(event.data.triggerElement)!,
                    targetUrl: event.data.targetUrl,
                    xPath,
                });
            });
    }

    private handleServiceToolFieldChangeEvent(): void {
        this.eventBus
            .on<TrackingServiceToolFieldChangeEvent>('st_field_change_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingServiceToolFieldChangeEvent) => {
                this.trackingService.trackServiceToolEvent({
                    eventAction: 'field_change',
                    id: event.data.id,
                    name: event.data.name,
                    state: event.data.state,
                    insuredPerson: event.data.insuredPerson,
                    fieldName: event.data.fieldName,
                    triggerElement: event.data.triggerElement,
                    duration: event.data.duration,
                });
            });
    }

    private handleServiceToolFieldCorrectionEvent(): void {
        this.eventBus
            .on<TrackingServiceToolFieldCorrectionEvent>('st_field_correction_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingServiceToolFieldCorrectionEvent) => {
                this.trackingService.trackServiceToolEvent({
                    eventAction: 'field_correction',
                    id: event.data.id,
                    name: event.data.name,
                    state: event.data.state,
                    insuredPerson: event.data.insuredPerson,
                    fieldName: event.data.fieldName,
                    triggerElement: event.data.triggerElement,
                    duration: event.data.duration,
                });
            });
    }

    private handleServiceToolInfoIconEvent(): void {
        this.eventBus
            .on<TrackingServiceToolInfoIconEvent>('st_info_icon_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingServiceToolInfoIconEvent) => {
                this.trackingService.trackServiceToolEvent({
                    eventAction: 'info_icon',
                    id: event.data.id,
                    name: event.data.name,
                    state: event.data.state,
                    clickLabel: 'info-icon',
                    fieldName: event.data.fieldName,
                    triggerElement: 'BIG-UI-INFO-BUTTON',
                });
            });
    }

    private handleServiceToolFormSubmitEvent(): void {
        this.eventBus
            .on<TrackingServiceToolFormSubmitEvent>('st_form_submit_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingServiceToolFormSubmitEvent) => {
                this.trackingService.trackServiceToolEvent({
                    eventAction: 'form_submit',
                    id: event.data.id,
                    name: event.data.name,
                    state: event.data.state,
                    clickLabel: event.data.clickLabel,
                    submitState: event.data.submitState,
                    resultNumber: event.data.resultNumber,
                    triggerElement: 'BIG-UI-BUTTON',
                    errorFields: event.data.errorFields,
                });
            });
    }

    private handleServiceToolImpressionEvent(): void {
        this.eventBus
            .on<TrackingServiceToolImpressionEvent>('st_impression_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingServiceToolImpressionEvent) => {
                this.trackingService.trackServiceToolEvent({
                    eventAction: 'impression',
                    id: event.data.id,
                    name: event.data.name,
                    state: event.data.state,
                });
            });
    }

    private handleServiceToolShowOrHideContentEvent(): void {
        this.eventBus
            .on<TrackingServiceToolShowOrHideContentEvent>('st_show_or_hide_content_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingServiceToolShowOrHideContentEvent) => {
                this.trackingService.trackServiceToolEvent({
                    eventAction: event.data.state === 'open' ? 'show_content' : 'hide_content',
                    id: event.data.id,
                    name: event.data.name,
                    state: event.data.state,
                    clickLabel: event.data.accordionTitle,
                });
            });
    }

    private handleServiceToolResultClick(): void {
        this.eventBus
            .on<TrackingServiceToolResultClickEvent>('st_result_click_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingServiceToolResultClickEvent) => {
                this.trackingService.trackServiceToolEvent({
                    eventAction: 'result_click',
                    id: event.data.id,
                    name: event.data.name,
                    state: event.data.state,
                    insuredPerson: event.data.insuredPerson,
                    clickLabel: event.data.clickLabel,
                    resultNumber: event.data.resultNumber,
                    fieldName: event.data.fieldName,
                    resultClickType: event.data.resultClickType,
                    checkedBenefits: event.data.checkedBenefits,
                    totalBenefits: event.data.totalBenefits,
                });
            });
    }

    private handleServiceToolResultInfoIconEvent(): void {
        this.eventBus
            .on<TrackingServiceToolResultInfoIconClickEvent>('st_result_info_icon_click_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingServiceToolResultInfoIconClickEvent) => {
                this.trackingService.trackServiceToolEvent({
                    eventAction: 'result_click',
                    id: event.data.id,
                    name: event.data.name,
                    state: event.data.state,
                    insuredPerson: event.data.insuredPerson,
                    clickLabel: 'info-icon',
                    resultNumber: event.data.resultNumber,
                    fieldName: event.data.fieldName,
                    triggerElement: 'BIG-UI-INFO-BUTTON',
                    resultClickType: 'info_icon',
                });
            });
    }

    private handleServiceToolPopupClickClickEvent(): void {
        this.eventBus
            .on<TrackingServiceToolPopupClickEvent>('st_popup_click_event')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((event: TrackingServiceToolPopupClickEvent) => {
                this.trackingService.trackServiceToolEvent({
                    eventAction: 'popup_click',
                    id: event.data.id,
                    name: event.data.name,
                    state: event.data.state,
                    clickLabel: event.data.clickLabel,
                    popupDuration: event.data.popupDuration,
                    resultNumber: event.data.resultNumber,
                    resultClickType: 'internal_link',
                });
            });
    }
}
