import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type ParagraphQuickLinkListModel } from './quick-link-list.model';
import { type QuickLinkListResource } from './quick-link-list.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class QuickLinkListMapper extends ResourceMapper<ParagraphQuickLinkListModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphQuickLinks;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: QuickLinkListResource, collection: ResourceCollection): ParagraphQuickLinkListModel {
        return {
            type: ParagraphTypes.QuickLinkList,
            model: {
                heading: resource.attributes.heading ?? undefined,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                items: this.resourceIdentifierMapper.mapMultiple(resource.relationships.quicklinks_items.data, collection)!,
            },
        };
    }
}
