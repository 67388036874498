import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigAccordionItemMapper } from '../mappers/node-big-accordion-item.mapper';

export const provideNodeBigAccordionItem = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigAccordionItemMapper.type],
            useClass: NodeBigAccordionItemMapper,
        },
    ]);
