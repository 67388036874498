import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type FilterableLinkListModel } from './filterable-link-list.model';
import { type FilterableLinkListResource } from './filterable-link-list.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class FilterableLinkListMapper extends ResourceMapper<FilterableLinkListModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigFilterableLinkList;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: FilterableLinkListResource, collection: ResourceCollection): FilterableLinkListModel {
        return {
            type: ParagraphTypes.FilterableLinkList,
            headlineBefore: resource.attributes.filterable_link_hl_before,
            headlineAfter: resource.attributes.filterable_link_hl_after,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            linkLists: this.resourceIdentifierMapper.mapMultiple(resource.relationships.filterable_link_lists.data, collection)!,
        };
    }
}
