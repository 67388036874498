import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { SearchBoxMapper } from './search-box.mapper';

export const provideSearchBoxParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[SearchBoxMapper.type], useClass: SearchBoxMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./search-box.component'),
                type: ParagraphTypes.C2aSearch,
            },
            multi: true,
        },
    ]);
