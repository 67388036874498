import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type BgmLinkModel } from './bgm-link.model';
import { type BgmLinkResource } from './bgm-link.resource';

@Injectable({ providedIn: 'any' })
@RestApiResourceMapper
export class BgmLinkMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.BgmLink;

    public map(resource: BgmLinkResource): BgmLinkModel {
        return {
            link: resource.attributes.data.url,
        };
    }
}
