import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type UserDataModel } from './user-data.model';
import { type UserDataResource } from './user-data.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class UserDataMapper extends ResourceMapper<UserDataModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigUserData;

    public map(resource: UserDataResource): UserDataModel {
        return {
            type: ParagraphTypes.UserData,
            link: {
                title: resource.attributes.link?.title ?? 'Zum Posteingang',
                uri: resource.attributes.link?.uri,
            },
        };
    }
}
