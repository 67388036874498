import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type MyBigGeneralModel } from './mybig-general.model';
import { type MybigGeneralResource } from './mybig-general.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class MybigGeneralMapper extends ResourceMapper<MyBigGeneralModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigMyBigGeneral;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: MybigGeneralResource, collection: ResourceCollection): MyBigGeneralModel {
        return {
            type: ParagraphTypes.MyBigGeneral,
            title: resource.attributes.title,
            icon: resource.relationships.icon.data?.meta?.uri,
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.paragraphs.data, collection),
        };
    }
}
