import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { type ServiceToolsMapItem, SERVICE_TOOLS_MAP } from '@big-direkt/service-tools/base';
import { type ContributionCalculatorComponent } from '../components/contribution-calculator.component';

export const provideContributionCalculatorServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/contribution-calculator.component'),
                id: 'contribution-calculator',
            } as ServiceToolsMapItem<ContributionCalculatorComponent>,
            multi: true,
        },
    ]);
