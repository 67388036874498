import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigTaggedArticleOverviewMapper } from '../mappers/node-big-tagged-article-overview.mapper';

export const provideNodeBigTaggedArticleOverview = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigTaggedArticleOverviewMapper.type],
            useClass: NodeBigTaggedArticleOverviewMapper,
        },
    ]);
