import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type HeadingModel, ParagraphTypes } from '@big-direkt/utils/shared';
import { type HeadingResource } from './heading.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class HeadingMapper extends ResourceMapper<HeadingModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigHeading;

    public map(resource: HeadingResource): HeadingModel {
        return {
            type: ParagraphTypes.Heading,
            level: resource.attributes.heading_level,
            content: resource.attributes.heading,
            fullWidth: false,
        };
    }
}
