import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { NodeArticleMapper } from './node-article.mapper';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigTemplateMapper extends NodeArticleMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeBigTemplate;
}
