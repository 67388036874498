import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeBigPublicDeliveryResource } from '../resources/node-big-public-delivery.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigPublicDeliveryMapper extends ResourceMapper<NodeBigPublicDeliveryResource> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodePublicDelivery;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeBigPublicDeliveryResource): NodeBigPublicDeliveryResource {
        return resource;
    }
}
