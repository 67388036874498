import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { SearchableLinkListMapper } from './searchable-link-list.mapper';

export const provideSearchableLinkListParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[SearchableLinkListMapper.type], useClass: SearchableLinkListMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./searchable-link-list.component'),
                type: ParagraphTypes.SearchableLinkList,
            },
            multi: true,
        },
    ]);
