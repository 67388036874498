import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type AidsItemModel } from '../model/aids-item.model';
import { type AidsResource } from '../resources/aids.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class AidsMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.AppliancesSearchAids;

    public map(resource: AidsResource): AidsItemModel[] {
        return resource.attributes.data;
    }
}
