import { Injectable } from '@angular/core';
import { type NodeModel, NodeTypes } from '@big-direkt/utils/shared';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeBigEpqResource } from '../resources/node-big-epq.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigEpqMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeBigEpq;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeBigEpqResource, collection: ResourceCollection): NodeModel {
        const node: NodeModel =  {
            type: NodeTypes.Epq,
            nodeId: resource.attributes.node_id ?? 0,
            heading: resource.attributes.headline_h1 ?? resource.attributes.title,
            introduction: resource.attributes.plain_text_introduction,
            metadata: resource.attributes.metatag ?? [],
            breadcrumbs: resource.attributes.breadcrumbs ?? [],
            created: resource.attributes.created,
            changed: resource.attributes.changed,
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
        };

        return node;
    }
}
