import { Injectable, inject } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { NodeTypes, type NodeModel } from '@big-direkt/utils/shared';
import { type NodeBigNewsResource } from '../resources/node-big-news.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigNewsMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeBigNews;
    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: NodeBigNewsResource, collection: ResourceCollection): NodeModel {
        const node: NodeModel = {
            type: NodeTypes.News,
            nodeId: resource.attributes.node_id ?? 0,
            heading: resource.attributes.headline_h1 ?? resource.attributes.title,
            introduction: resource.attributes.plain_text_introduction,
            publicationDate: resource.attributes.news_publication_date,
            publicationLocation: resource.attributes.news_publication_location,
            metadata: resource.attributes.metatag ?? [],
            breadcrumbs: resource.attributes.breadcrumbs ?? [],
            created: resource.attributes.created,
            changed: resource.attributes.changed,
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
            href: resource.attributes.path?.alias,
        };

        if (resource.relationships.image_introduction.data) {
            node.pageTitle = {
                picture: this.resourceIdentifierMapper.map(resource.relationships.image_introduction.data, collection),
            };
        }

        if (resource.relationships.news_image_introduction.data) {
            node.newsIntroductionImage = this.resourceIdentifierMapper.map(resource.relationships.news_image_introduction.data, collection);
        }

        return node;
    }
}
