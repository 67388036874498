import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { SERVICE_TOOLS_MAP } from '@big-direkt/service-tools/base';

export const provideDiagnosisCodeSearch = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/diagnosis-code-search.component'),
                id: 'diagnosis-code-search',
            },
            multi: true,
        },
    ]);
