import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type InstructionModel } from './instruction.model';
import { type InstructionResource } from './instruction.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class InstructionMapper extends ResourceMapper<InstructionModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphInstruction;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: InstructionResource, collection: ResourceCollection): InstructionModel {
        return {
            type: ParagraphTypes.Instruction,
            title: resource.attributes.card_title,
            text: resource.attributes.card_text,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            items: this.resourceIdentifierMapper.mapMultiple(resource.relationships.list_items.data, collection)!,
        };
    }
}
