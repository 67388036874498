import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type List } from './list.model';
import { type ListResource } from './list.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class ListMapper extends ResourceMapper<List> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphList;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: ListResource, collection: ResourceCollection): List {
        return {
            type: ParagraphTypes.List,
            rendering: resource.attributes.list_rendering,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            columns: resource.attributes.list_two_columned ? 2 : 1,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            items: this.resourceIdentifierMapper.mapMultiple(resource.relationships.list_items.data, collection)!,
        };
    }
}
