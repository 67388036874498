import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { ParagraphTypes, type SearchableLinkListModel } from '@big-direkt/utils/shared';
import { type SearchableLinkListResource } from './searchable-link-list.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class SearchableLinkListMapper extends ResourceMapper<SearchableLinkListModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigSearchableLinkList;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: SearchableLinkListResource, collection: ResourceCollection): SearchableLinkListModel {
        return {
            type: ParagraphTypes.SearchableLinkList,
            textBefore: resource.attributes.searchable_link_hl_before,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            linkLists: this.resourceIdentifierMapper.mapMultiple(resource.relationships.searchable_link_lists.data, collection)!,
        };
    }
}
