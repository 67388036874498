import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { UserDetailsItemMapper } from './user-details-item.mapper';
import { UserDetailsMapper } from './user-details.mapper';

export const provideUserDetailsParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[UserDetailsMapper.type], useClass: UserDetailsMapper },
        { provide: MapperInjectorService.tokens[UserDetailsItemMapper.type], useClass: UserDetailsItemMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./user-details.component'),
                type: ParagraphTypes.UserDetails,
            },
            multi: true,
        },
    ]);
