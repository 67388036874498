import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TrackingService } from './services/tracking.service';
import { TrackingEventHandlerService } from './services/tracking-event-handler.service';

@NgModule({
    imports: [RouterModule],
})
export class TrackingModule {
    // Services are injected here due to treeshaking issues otherwise
    public constructor(private readonly trackingService: TrackingService, private readonly trackingEventHandlerService: TrackingEventHandlerService) {
        this.trackingEventHandlerService.initializeEvents();
    }
}
