import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { FilterableLinkListMapper } from './filterable-link-list.mapper';

export const provideFilterableLinkListParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[FilterableLinkListMapper.type], useClass: FilterableLinkListMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./filterable-link-list.component'),
                type: ParagraphTypes.FilterableLinkList,
            },
            multi: true,
        },
    ]);
