import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { VaccinationCheckMapper } from '../mappers/vaccination-check.mapper';
import type VaccinationCheckComponent from '../components/vaccination-check.component';
import { SERVICE_TOOLS_MAP, type ServiceToolsMapItem } from '@big-direkt/service-tools/base';

export const provideVaccinationCheckServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [ResourceTypes.VaccinationCheckServiceToolData]: '/service-tool-data/vaccination-check.json' },
            multi: true,
        },
        {
            provide: MapperInjectorService.tokens[ResourceTypes.VaccinationCheckServiceToolData],
            useClass: VaccinationCheckMapper,
        },
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/vaccination-check.component'),
                id: 'vaccination-check',
            } as ServiceToolsMapItem<VaccinationCheckComponent>,
            multi: true,
        },
    ]);
