import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type PublicDeliveryModel } from './public-delivery.model';
import { type PublicDeliveryResource } from './public-delivery.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class PublicDeliveryMapper extends ResourceMapper<PublicDeliveryModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigPublicDelivery;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: PublicDeliveryResource): PublicDeliveryModel {
        return {
            type: ParagraphTypes.PublicDelivery,
            title: resource.attributes.title,
        };
    }
}
