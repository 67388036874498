import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type ButtonModel } from './button.model';
import { type ButtonResource } from './button.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class ButtonMapper extends ResourceMapper<ButtonModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigButton;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: ButtonResource): ButtonModel {
        return {
            type: ParagraphTypes.Button,
            alignment: resource.attributes.alignment ?? 'center',
            style: resource.attributes.button_style ?? 'secondary',
            link: {
                title: resource.attributes.link.title,
                href: resource.attributes.link.uri,
                target: undefined,
            },
        };
    }
}
