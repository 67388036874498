import { Injectable } from '@angular/core';
import { type PreventionCheckModel } from '../models/prevention-check.model';
import { type PreventionCheckResource } from '../models/prevention-check.resource';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class PreventionCheckMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.PreventionCheckServiceToolData;

    public map(resource: PreventionCheckResource): PreventionCheckModel[] {
        return resource.attributes.map(item => ({
            id: item.id,
            gender: this.mapGender(item.gender),
            ageFrom: item.age_from,
            ageTill: item.age_till,
            ageText: item.age_text,
            interval: item.interval > 0 ? item.interval : undefined,
            intervalText: item.interval_text.replace(/\\n/g, '').replace(/\\r/g, '').replace(/\\"/g, '"'),
            examinationName: item.examination_name,
            examinationText: item.examination_text.replace(/\\n/g, '').replace(/\\r/g, '').replace(/\\"/g, '"'),
        }));
    }

    private mapGender(gender: string): 'f' | 'm' | undefined {
        switch (gender) {
            case 'f':
                return 'f';
            case 'm':
                return 'm';
            default:
                return undefined;
        }
    }
}
