import { Injectable } from '@angular/core';
import { type TopBarModel } from '@big-direkt/utils/shared';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeBigTopBarResource } from '../resources/node-big-top-bar.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigTopBarMapper extends ResourceMapper<TopBarModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeBigTopBar;

    public map(resource: NodeBigTopBarResource): TopBarModel {
        return {
            id: resource.id,
            nodeId: resource.attributes.node_id ?? 0,
            html: resource.attributes.body?.value,
        };
    }
}
