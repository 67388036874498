import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes, type ResourceCollection } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type ImageModel } from './image.model';
import { type ImageResource } from './image.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class ImageMapper extends ResourceMapper<ImageModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigImage;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: ImageResource, collection: ResourceCollection): ImageModel {
        return {
            type: ParagraphTypes.Image,
            heading: resource.attributes.heading,
            text: resource.attributes.text?.value ?? '',
            headingLevel: resource.attributes.heading_level,
            imageRendering: resource.attributes.image_rendering,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            image: this.resourceIdentifierMapper.map(resource.relationships.image.data, collection)!,
        };
    }
}
