import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { SERVICE_TOOLS_MAP, type ServiceToolsMapItem } from '@big-direkt/service-tools/base';
import { type StudentInsuranceCheckComponent } from '../components/student-insurance-check.component';

export const provideStudentInsuranceCheckServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/student-insurance-check.component'),
                id: 'student-insurance-check',
            } as ServiceToolsMapItem<StudentInsuranceCheckComponent>,
            multi: true,
        },
    ]);
