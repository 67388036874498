import { SubjectAreaNodesMapper } from './subject-area-nodes.mapper';
import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { SubjectAreasParagraphMapper } from './subject-areas.paragraph.mapper';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { MapperInjectorService as RestApiMapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { SubjectAreasMapper } from './subject-areas.mapper';

export const provideSubjectAreasParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.SubjectAreaNodes]: '/api/subject-area-nodes' }, multi: true },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.SubjectAreas]: '/api/subject-area-terms' }, multi: true },
        { provide: RestApiMapperInjectorService.tokens[SubjectAreaNodesMapper.type], useClass: SubjectAreaNodesMapper },
        { provide: RestApiMapperInjectorService.tokens[SubjectAreasMapper.type], useClass: SubjectAreasMapper },
        { provide: MapperInjectorService.tokens[SubjectAreasParagraphMapper.type], useClass: SubjectAreasParagraphMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./subject-areas.component'),
                type: ParagraphTypes.SubjectAreas,
            },
            multi: true,
        },
    ]);
