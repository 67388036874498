import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { SERVICE_TOOLS_MAP, type ServiceToolsMapItem } from '@big-direkt/service-tools/base';
import type CoPaymentCalculatorComponent from '../components/co-payment-calculator.component';

export const provideCoPaymentCalculatorServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/co-payment-calculator.component'),
                id: 'co-payment-calculator',
            } as ServiceToolsMapItem<CoPaymentCalculatorComponent>,
            multi: true,
        },
    ]);
