import { Injectable } from '@angular/core';
import { type NodeModel, NodeTypes } from '@big-direkt/utils/shared';
import { JsonApiResourceMapper, type ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type NodeBigTaggedArticleOverviewResource } from '../resources/node-big-tagged-article-overview.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class NodeBigTaggedArticleOverviewMapper extends ResourceMapper<NodeModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.NodeBigTaggedArticleOverview;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: NodeBigTaggedArticleOverviewResource, collection: ResourceCollection): NodeModel {
        const node: NodeModel = {
            type: NodeTypes.Article,
            nodeId: resource.attributes.node_id ?? 0,
            heading: resource.attributes.headline_h1 ?? resource.attributes.title,
            introduction: resource.attributes.plain_text_introduction,
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.content.data, collection),
            breadcrumbs: resource.attributes.breadcrumbs ?? [],
            metadata: resource.attributes.metatag ?? [],
            created: resource.attributes.created,
            changed: resource.attributes.changed,
        };

        if (resource.relationships.image_introduction.data) {
            node.pageTitle = {
                picture: this.resourceIdentifierMapper.map(resource.relationships.image_introduction.data, collection),
            };
        }

        return node;
    }
}
