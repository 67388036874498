import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { QuickLinkLinkDownloadMapper } from './quick-link-download.mapper';
import { QuickLinkFileMapper } from './quick-link-file.mapper';
import { QuickLinkLinkMapper } from './quick-link-link.mapper';
import { QuickLinkListMapper } from './quick-link-list.mapper';
import { QuickLinkNodeMapper } from './quick-link-node.mapper';

export const provideQuickLinkListParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[QuickLinkFileMapper.type], useClass: QuickLinkFileMapper },
        { provide: MapperInjectorService.tokens[QuickLinkLinkDownloadMapper.type], useClass: QuickLinkLinkDownloadMapper },
        { provide: MapperInjectorService.tokens[QuickLinkLinkMapper.type], useClass: QuickLinkLinkMapper },
        { provide: MapperInjectorService.tokens[QuickLinkListMapper.type], useClass: QuickLinkListMapper },
        { provide: MapperInjectorService.tokens[QuickLinkNodeMapper.type], useClass: QuickLinkNodeMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./quick-link-list.component'),
                type: ParagraphTypes.QuickLinkList,
            },
            multi: true,
        },
    ]);
