import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { type CustomerSegmentModel } from './taxonomy-term-customer-segment.model';
import { type CustomerSegmentResource } from './taxonomy-term-customer-segment.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class TaxonomyTermCustomerSegmentMapper extends ResourceMapper<CustomerSegmentModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.TaxonomyTermCustomerSegment;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: CustomerSegmentResource): CustomerSegmentModel {
        return {
            displayName: resource.attributes.name,
            key: resource.attributes.key,
            id: resource.attributes.drubal_internal__tid,
        };
    }
}
