import { type SubjectAreasResource } from './subject-areas.paragraph.resource';
import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type SubjectAreasModel } from './subject-areas.paragraph.model';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class SubjectAreasParagraphMapper extends ResourceMapper<SubjectAreasModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigSubjectAreas;

    public constructor(protected readonly resourceIdentifierMapper: ResourceIdentifierMapperService) {
        super();
    }

    public map(resource: SubjectAreasResource): SubjectAreasModel {
        return {
            type: ParagraphTypes.SubjectAreas,
            filterTitle: resource.attributes.title,
            subjectAreaId: resource.relationships.subject_area.data.meta.drupal_internal__target_id,
        };
    }
}
