import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { DashboardBigtionaerMapper } from './dashboard-bigtionaer.mapper';

export const provideDashboardBigtionaerParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[DashboardBigtionaerMapper.type], useClass: DashboardBigtionaerMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./dashboard-bigtionaer.component'),
                type: ParagraphTypes.DashboardBigtionaer,
            },
            multi: true,
        },
    ]);
