import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigTopBarMapper } from '../mappers/node-big-top-bar.mapper';

export const provideNodeBigTopBar = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigTopBarMapper.type],
            useClass: NodeBigTopBarMapper,
        },
    ]);
