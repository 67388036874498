import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { SERVICE_TOOLS_MAP, type ServiceToolsMapItem } from '@big-direkt/service-tools/base';
import type SpecialistSearchComponent from '../components/specialist-search.component';
import { SpecialistSearchSpecialistsMapper } from '../mappers/specialist-search-specialists.mapper';
import { SpecialistSearchSpecialityMapper } from '../mappers/specialist-search-speciality.mapper';

export const provideSpecialistSearchServiceTool = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [ResourceTypes.SpecialistSearchSpecialists]: 'api/service-tool/specialist-search/specialists' },
            multi: true,
        },
        {
            provide: MapperInjectorService.tokens[ResourceTypes.SpecialistSearchSpecialists],
            useClass: SpecialistSearchSpecialistsMapper,
        },
        {
            provide: RESOURCE_PATH_TOKEN,
            useValue: { [ResourceTypes.SpecialistSearchSpeciality]: 'api/service-tool/specialist-search/specialties' },
            multi: true,
        },
        {
            provide: MapperInjectorService.tokens[ResourceTypes.SpecialistSearchSpeciality],
            useClass: SpecialistSearchSpecialityMapper,
        },
        {
            provide: SERVICE_TOOLS_MAP,
            useValue: {
                import: async () => import('../components/specialist-search.component'),
                id: 'specialist-search-new',
            } as ServiceToolsMapItem<SpecialistSearchComponent>,
            multi: true,
        },
    ]);
