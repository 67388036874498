import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigAccordionMapper } from '../mappers/node-big-accordion.mapper';

export const provideNodeBigAccordion = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigAccordionMapper.type],
            useClass: NodeBigAccordionMapper,
        },
    ]);
