import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeBigEpqMapper } from '../mappers/node-big-epq.mapper';

export const provideNodeBigEpq = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeBigEpqMapper.type],
            useClass: NodeBigEpqMapper,
        },
    ]);
