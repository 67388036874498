import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { GapRemoverModel } from './gap-remover.model';
import { GapRemoverResource } from './gap-remover.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class GapRemoverMapper extends ResourceMapper<GapRemoverModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigGapRemover;

    public map(resource: GapRemoverResource): GapRemoverModel {
        return {
            type: ParagraphTypes.GapRemover,
            gapOverride: resource.attributes.gap_override ?? 0,
        };
    }
}
