import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { BgmButtonMapper } from './bgm-button.mapper';
import { BgmLinkMapper } from './bgm-link.mapper';
import { MapperInjectorService as JsonApiMapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { MapperInjectorService as RestApiMapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';

export const provideBgmButtonParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: JsonApiMapperInjectorService.tokens[BgmButtonMapper.type], useClass: BgmButtonMapper },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.BgmLink]: '/api/bgm/sign' }, multi: true },
        { provide: RestApiMapperInjectorService.tokens[BgmLinkMapper.type], useClass: BgmLinkMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./bgm-button.component'),
                type: ParagraphTypes.BgmButton,
            },
            multi: true,
        },
    ]);
