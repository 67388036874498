import { type Route } from '@angular/router';
import { ContentResolverService } from '@big-direkt/content';
import { SearchComponent } from '@big-direkt/search';
import { ContentComponent } from './content/content.component';
import { CanActivateBlacklistedUrlsGuard, CanActivatePhoneNumbersGuard } from './guards';

export const appRoutes: Route[] = [
    {
        path: 'de/meinebig',
        loadChildren: async () => (await import('./my-big/my-big.module')).MyBigModule,
        canActivate: [CanActivateBlacklistedUrlsGuard],
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'suche',
        component: SearchComponent,
    },
    {
        path: '**',
        component: ContentComponent,
        resolve: { content: ContentResolverService },
        canActivate: [CanActivateBlacklistedUrlsGuard, CanActivatePhoneNumbersGuard],
        data: { showPageTitle: true },
    },
];
