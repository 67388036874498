import { inject, Injectable } from '@angular/core';
import { DefaultUrlSerializer, type UrlSerializer, type UrlTree } from '@angular/router';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { UserRepository } from '@big-direkt/state/user';
import { type QuickLinkListItemModel } from '@big-direkt/ui/quick-link-list';
import { type QuickLinkLinkResource } from './quick-link-link.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class QuickLinkLinkMapper extends ResourceMapper<QuickLinkListItemModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphQuickLinkLink;

    private readonly userRepository = inject(UserRepository);

    public map(resource: QuickLinkLinkResource): QuickLinkListItemModel {
        return {
            icon: resource.relationships.icon.data?.meta?.uri,
            title: resource.attributes.link.title,
            url: this.forwardSession(resource.attributes.link.uri, resource.attributes.forward_session),
        };
    }

    private forwardSession(url: string, forwardSession?: boolean): string {
        const token = this.userRepository.getToken();

        if (url.startsWith('http://') || url.startsWith('https://')) {
            // for absolute urls
            const urlParts: URL = new URL(url);

            if (forwardSession === true && token) {
                urlParts.searchParams.append('jwt', token);
            }

            return urlParts.toString();
        }

        // for relative paths
        const urlSerializer: UrlSerializer = new DefaultUrlSerializer();
        const urlTree: UrlTree = urlSerializer.parse(url);

        if (forwardSession === true) {
            urlTree.queryParams = { ...urlTree.queryParams, jwt: token };
        }

        return urlSerializer.serialize(urlTree);
    }
}
